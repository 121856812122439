import { io } from "socket.io-client";
import { decryptData } from "./utils/utils";

let socket = null;
let connecting = false;
const SOCKET_URL = process.env.React_App_Socket_Url; 
export const getSocket = () => {

    if (socket && socket.connected) {
        return socket; 
    }

    if (connecting) {
        console.log("Socket connection already in progress...");
        return socket;
    }

    connecting = true; 

    socket = io(SOCKET_URL, {
        withCredentials: true,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 5000,
    });

    socket.on("connect", () => {
        console.log("Successfully connected to the server");
        socket.emit("listen", decryptData(localStorage?.getItem("token")));
        connecting = false;
    });

    socket.on("disconnect", () => {
        console.log("Disconnected from server");
        connecting = false; 
    });

    socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
        connecting = false; 
    });

    return socket;
};
