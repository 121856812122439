import { Store } from "react-notifications-component";
import { decryptData } from "../../utils/utils";

export const BaseUrl = process.env.React_App_BaseurlAdmin;

export const auth = () => {
  return {
    headers: {
      Authorization: `Bearer ${decryptData(localStorage.getItem("token"))}`,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

export const ShowMsg = (title,msg,type) => {
  return Store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1500,
      onScreen: true
    }
  })
};


// export const dateConverter = (isoString) => {
//   if(isoString) {
//     const date = new Date(isoString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//   const day = String(date.getDate()).padStart(2, '0');
//   // return `${year}-${month}-${day}`;
//   return `${day}-${month}-${year}`
//   }
// };


