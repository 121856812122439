/** @format */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "./CSS/MediaQuery.css";
import "./index.css";
import './CSS/Print.css'
import { adminRoutes, employeeRoutes, residentRoutes } from './Route/AllRoutes';
import ProtectedRoute from './Route/ProtectedRoute';
import Profile from "./Pages/Profile/Profile";
import axios from "axios";
import { LoginSlice, userProfile } from "./store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./admin/component/form/ChangePassword";
import Loader from "./components/Loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { decryptData } from "./utils/utils";

const NotFound = lazy(() => import("./Pages/404/NotFound"));
const Login = lazy(() => import("./Pages/Login/Login"));
const UnauthorizedPage = lazy(() => import("./Route/UnauthorizedPage"))
// const AdminPatientList = lazy(() =>
//   import('./Pages/Patients/PatientList').then((module) => ({
//     default: module.InnerPatientList,
//   }))
// );
const NotesLibrary2 = lazy(() => import('./admin/pages/Dashboard/NotesLibrary2'))

function App() {
  const ProfileDetails = useSelector(userProfile);
  const storedToken = localStorage.getItem("token");
  const token = storedToken ? decryptData(storedToken) : null;
  // const tokenUserType = decryptData(localStorage.getItem("user"),true)?.userType
  const storedUser = localStorage.getItem("user");
  const tokenUserType = storedUser ? decryptData(storedUser, true)?.userType : null;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const location = useLocation();
  const newAdminRoutes = [...adminRoutes]
    if(ProfileDetails?.tier === "Growth" && !adminRoutes?.includes({ path: '/dashboard/notes', element: <NotesLibrary2 /> })) {
      newAdminRoutes?.push({ path: '/dashboard/notes', element: <NotesLibrary2 /> })
    }

  useEffect(() => {
    const fetchProfile = async () => {
      let endpoint;
      // eslint-disable-next-line default-case
      switch (tokenUserType) {
        case 'Admin':
          endpoint = `${process.env.React_App_Baseurl}Admin/getProfile`;
          break;
        case 'Employee':
          endpoint = `${process.env.React_App_Baseurl}employee/getProfile`;
          break;
        case 'Patient':
          endpoint = `${process.env.React_App_Baseurl}Patient/getProfile`;
          break;
          case 'Guardian':
          endpoint = `${process.env.React_App_Baseurl}Patient/getProfile`;
          break;
      }
      setLoading(true);
      try {
        const res = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = {
          profile: { accessToken: token, data: res?.data?.data },
          timeStamp: Date.now(),
        };
        if (location.pathname === "/") {
          // eslint-disable-next-line default-case
          switch (tokenUserType) {
            case 'Admin':
              navigate("/dashboard/homepage", { replace: true });
              break;
            case 'Employee':
              navigate("/Home", { replace: true });
              break;
            case 'Patient':
              navigate("/patient_panel", { replace: true });
              break;
              case 'Guardian':       
              navigate("/patient_panel",{ replace: true });
              break;
          }
        }
        dispatch(LoginSlice(data));
      } catch (e) {
        localStorage.clear()
        if (location.pathname !== "/change-password") {
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };
    if (token) {
      fetchProfile(token);
    } else {
      localStorage.clear()
      if (location.pathname !== "/change-password") {
        navigate("/")
      }
      setLoading(false);
    }
  }, [token]);
  return (
    <>
      <ReactNotifications />
      <Suspense fallback={<Loader />}>
        {loading ? <Loader /> : <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />

          {/* Admin Routes */}
          {newAdminRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute
                    element={route.element}
                    allowedRoles={['Admin']}
                  />
                </Suspense>
              }
            />
          ))}

          {/* Employee Routes */}
          {employeeRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute
                    element={route.element}
                    allowedRoles={['Admin', 'Employee','Guardian']}
                  />
                </Suspense>
              }
            />
          ))}
          {/* Resident Routes */}
          {residentRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute
                    element={route.element}
                    allowedRoles={['Patient', "Guardian" ]}
                  />
                </Suspense>
              }
            />
          ))}
          {/* Profile Route */}
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          {/* Unauthorized Route */}
          <Route
            path="/unauthorized"
            element={
              <Suspense fallback={<Loader />}>
                <UnauthorizedPage />
              </Suspense>
            }
          />
          {/* Not Found Route */}
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>}
      </Suspense>
    </>
  );
}

export default App;
