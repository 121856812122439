import { decryptData } from "./utils";


export const auth = () => {
  return {
    headers: {
      Authorization: `Bearer ${decryptData(localStorage.getItem("token"))}`,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

