import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import { createApi, getApi, showNotification } from "../../Repository/Apis";

const CanclePlan = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [response, setResponse] = useState({});



  const fetchHandler = () => {
    getApi({
      url: `stripe/subscription/change-status-request`,
      setResponse: setPlans,
      showAlert: true
    });

  };

  useEffect(() => {
    if (props.show) {
      fetchHandler();
    }
  }, [props.show]);


  const resetFields = () => {
    setOtp("");
  };


  const handleResendOtp = async () => {
    try {
      await getApi({
        url: `stripe/subscription/change-status-request`,
        setLoading,
        setResponse,
      });
      showNotification({
        message: "OTP resent successfully.",
        type: "success",
      });
      resetFields()
    } catch (error) {
      const msg = error?.response?.data?.message || "Failed to resend OTP.";
      showNotification({ message: msg, type: "danger" });
    }
  };

  const submitHandler = async () => {
    if (!otp) {
      showNotification({ message: "Please enter the OTP.", type: "danger" });
      return;
    }

    try {
      const res = await createApi({
        setLoading,
        url: `stripe/subscription/confirm-status`,
        payload: { otp },
        successMsg: props?.planCheck ? "The subscription plan has been resumed successfully" : "The plan you subscribed has been canceled"
      });
      //   showNotification({ message: "Subscription plan changed successfully.", type: "success" });
      if(res?.success) {
        resetFields();
        props.onHide();
      }
    } catch (error) {
      console.log("error", error);
      const msg =
        error?.response?.data?.message || "Failed to cancel subscription plan.";
      showNotification({ message: msg, type: "danger" });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        resetFields();
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <h5 className="fw-bold mb-0">{props?.planCheck ? `Confirm To Resume Plan` :` Confirm To Cancel Plan`}</h5>
      </Modal.Header>
      <ModalBody>
        <Row>
          <Form.Group className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <Form.Label className="fw-bold">Enter OTP</Form.Label>
              <button
                style={{ color: "#68BFCC" }}
                onClick={handleResendOtp}
                className="resend-otp-btn mb-2"
                type="button"
              >
                Resend OTP
              </button>
            </div>

            <Form.Control
              type="text"
              value={otp}
              onChange={(e) => {
                const value = e.target.value?.trim();
                if (/^\d*$/.test(value)) {
                  setOtp(value);
                }
              }}
              placeholder="Enter OTP"
            />
          </Form.Group>
        </Row>

        <Row className="justify-content-center mt-3">
          <Col md={12} className="text-center">
            <Button className="theme-button" onClick={submitHandler}>
             {props?.planCheck ? `Confirm Resume Plan` :` Confirm Cancel Plan`}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CanclePlan;
