/** @format */
import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Modal,
  Offcanvas,
  Table,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  createApi,
  getObjectUrlFromDownloadUrl,
  LogOutHandler,
  uploadDocument,
} from "../Repository/Apis";
import {
  AdminSidebar,
  FilesNames,
  Innernav,
  ResidentSidebar,
} from "../assets/Constant/Constant";
import { ClipLoader } from "react-spinners";
import { formatDateToMMDDYYYY } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../store/authSlice";
import CreateChat from "../Pages/Chat/CreateChat";
import CustomSelect from "../components/selector/CustomSelect";
import { employeeSidebarNav } from "../assets/Constant/Constant";
import { MdOutlineLibraryBooks, MdChatBubbleOutline, MdLogin } from "react-icons/md";
import axios from "axios";
export function OuterSidebar({ show, handleClose,routesMob }) {
  //"../../assets/Constant/Constant";
  const location = useLocation();
  const ProfileDetails = useSelector(userProfile);
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState([]);

  const [isChat, setIsChat] = useState(false);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  const newAdminSidebar = [...AdminSidebar];
  if (ProfileDetails?.tier === "Growth") {
    newAdminSidebar?.splice(6, 0, {
      icon: MdOutlineLibraryBooks,
      link: "/dashboard/notes",
      name: "Notes Library",
    });
  }
  useEffect(() => {

    if(routesMob){
      setNavItem(routesMob)
    }
  }, [routesMob]);

  return (
    <>
      <CreateChat show={isChat} handleClose={() => setIsChat(false)} />

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="Outer_sider_canvas_container"
      >
        <Offcanvas.Body className="Outer_sidebar_Body">
          <div className="close_btn">
            <i
              className="fa-solid fa-circle-xmark"
              onClick={() => handleClose()}
            ></i>
          </div>
          <div className="Logo_img">
            {ProfileDetails?.adminId?.logo ? (
              <img
                style={{
                  maxWidth: "155px",
                  maxHeight: "55px",
                  display: "block",
                  // margin: "auto",
                }}
                src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo)}
                alt=""
              />
            ) : (
              <img
                style={{
                  maxWidth: "155px",
                  maxHeight: "55px",
                  display: "block",
                }}
                src="/logo.png"
                alt=""
              />
            )}
          </div>

          <div className="outer-siderbar-nav">
            <ul className="Nav">
              {navItem?.map((nav, index) => (
                <li
                  key={`nav${index}`}
                  onClick={() => navigate(nav.link)}
                  className={location.pathname === nav.link && "active"}
                >
                  {typeof nav.icon === "string" ? (
                    <img src={getObjectUrlFromDownloadUrl(nav.img)} alt="" />
                  ) : (
                    <nav.icon />
                  )}
                  <span>{nav.name}</span>
                </li>
              ))}

              {ProfileDetails?.adminId?.permissionChat === true && (
                <li
                  onClick={() => {
                    handleClose();
                    setIsChat(true);
                  }}
                >
                  <span>
                    <MdChatBubbleOutline />
                  </span>
                  <span>Chat</span>
                </li>
              )}
              <li
                onClick={() => {
                  handleClose();
                  setIsChat(true);
                }}
              >
                <span>
                  <MdLogin />
                </span>
                <span>Logout</span>
              </li>
            </ul>
            {/* <div class="chatloficon-wrap">
              {ProfileDetails?.adminId?.permissionChat === true && (
                <span className="cursor-pointer d-block my-2 text-white">
                  <img
                    src="/Navbar/chatIcon.jpg"
                    className="navbar-notify-image d-block m-auto relative -left-[7px]"
                    onClick={() => {
                      handleClose();
                      setIsChat(true);
                    }}
                    alt=""
                  />
                  <span>
                    <MdChatBubbleOutline />
                  </span>
                  <span>Chat</span>
                </span>
              )}
              <span className="cursor-pointer d-block my-2 text-white">
                <img
                  src="/Navbar/chatIcon.jpg"
                  className="navbar-notify-image d-block m-auto relative -left-[7px]"
                  onClick={() => {
                    handleClose();
                    setIsChat(true);
                  }}
                  alt=""
                />
                <span>
                  <MdChatBubbleOutline />
                </span>
                <span>Chat</span>
              </span>
              <span
                onClick={logOut}
                className="cursor-pointer d-block text-white"
              >
                <img
                  src="/Navbar/logoutIcon.jpg"
                  className="navbar-notify-image d-block m-auto relative -left-[7px]"
                  onClick={() => {
                    handleClose();
                    setIsChat(true);
                  }}
                  alt=""
                />
                Logout
              </span>
            </div> */}
          </div>
          <h5 className="app-verion-text mb-0">App version 1.0</h5>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const SignatureModal = (props) => {
  const today = new Date();
  const stringDate = today.toISOString();
  const [time, setTime] = useState("");
  const [name, setName] = useState(props?.value);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date();
      const hours = today.getHours();
      const min = today.getMinutes();
      const seconds = today.getSeconds();
      setTime(`${hours}:${min}:${seconds}`);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function valueSetter() {
    props?.setValue(name);
    props?.setTime(time);
    props?.setDate(stringDate?.split("T")?.[0]);
    props.onHide();
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="sing_modla"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="singature_modal">
          <h6> Digitally Sign by {props?.value} </h6>
          <h6>
            {" "}
            Date : {stringDate && formatDateToMMDDYYYY(stringDate)} Time :{" "}
            {time}{" "}
          </h6>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter your Lorem Ipsum"
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={valueSetter}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const DocumentUploader = (props) => {
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [arr, setArr] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [patientId, setPaitentId] = useState("");

  const removeFile = (index) => {
    const filterThis = arr?.filter((_, i) => index !== i);
    setArr(filterThis);
  };

  // File Upload
  const filePayload = new FormData();
  filePayload.append("file", file);
  filePayload.append("type", fileType);
  const uploadFiles = async (e) => {
    e.preventDefault();
    await uploadDocument({
      payload: filePayload,
      setArr,
      setLoading: setUploading,
      patitentId: props.patitentId,
    });
    if (props) {
      props?.fetchDocument()
    }
  };

  const payload = {
    patientId: props?.patitentId ? props?.patitentId : patientId,
    data: arr,
  };

  const submitHandler = () => {
    const additionalFunctions = [props.onHide, props?.fetchDocument];
    createApi({
      url: `employee/createUploadDocument1`,
      payload,
      successMsg: "Uploaded !",
      setLoading: setSubmitLoading,
      additionalFunctions,
    });
  };

  const downloadHandler = async (endpoint) => {
    const fileVsersionName = endpoint.split("/")
    try {
      const response = await axios.get(endpoint, {
        responseType: 'blob',
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileURL;
      if (fileVsersionName) {
        link.setAttribute('download', fileVsersionName[fileVsersionName.length - 1]);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error('Document Not Found!!');
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="file-upload-modal">
        <form onSubmit={uploadFiles}>
          <div className="close-header">
            <h5>File Upload </h5>
            <i className="fa-solid fa-xmark" onClick={() => props.onHide()}></i>
          </div>

          <div className="wrapper">
            {/* <PatientComponent
                className={"search-field mb-3 absolute-container"}
                MainPatientId={setPaitentId}
              /> */}

            <div className="flexbox">
              {/* <div className="items"> */}
              {/* <p className="head">Actions</p> */}
              {/* <button type="submit">
                    {uploading ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      "Add Additional files"
                    )}
                  </button> */}
              {/* </div> */}
            </div>

            <Row>
              <Col xs={12} md={12} lg={4}>
                <div className="items mb-3 mb-xl-0">
                  <Form.Label className="fw-bold">File Type</Form.Label>
                  {/* <select onChange={(e) => setFileType(e.target.value)}>
                    <option value=""> Select Prefrence </option>
                    {FilesNames?.map((i) => (
                      <option value={i} key={i}>
                        {" "}
                        {i}{" "}
                      </option>
                    ))}
                  </select> */}
                  <CustomSelect
                    options={FilesNames}
                    onChange={(value) => setFileType(value)}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="items mb-3 mb-xl-0">
                  <Form.Label className="fw-bold">File Name</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="items mb-3 mb-xl-0">
                  <Form.Label className="fw-bold">Upload File</Form.Label>
                  <div>
                    <Button className="theme-button w-100" type="submit">
                      {uploading ? <ClipLoader color="#fff" /> : "Upload File"}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Table responsive bordered className="mt-3">
              <thead>
                <tr>
                  <th className="text-start">Type</th>
                  <th className="text-start">File</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {arr?.map((i, index) => (
                  <tr key={index}>
                    <td className="text-start"> {i.type} </td>
                    <td className="text-start">
                      {/* <a  target="_blank" rel="noreferrer">
                        View File
                      </a> */}
                      <Link className="view-btn" onClick={() => downloadHandler(getObjectUrlFromDownloadUrl(i?.document))} >
                        <i className="fa-solid fa-eye" />
                      </Link>
                    </td>
                    <td>
                      <div className="icon-joiner">
                        <span
                          className="del-btn cursor-pointer"
                          onClick={() => removeFile(index)}
                        >
                          <i className="fa-solid fa-trash-can" />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* <div className="btn-container">
                <button
                  className="upload_files"
                  onClick={() => submitHandler()}
                  type="button"
                >
                  {submitLoading ? <ClipLoader color="#fff" /> : "Upload Files"}
                </button>
              </div> */}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export function InnerSidebars({ show, handleClose, type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const ProfileDetails = useSelector(userProfile);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  const [currentNavList, setCurrentNavList] = useState([]);

  useEffect(() => {
    if (ProfileDetails?.userType === "Admin") {
      setCurrentNavList(AdminSidebar);
    } else if (ProfileDetails?.userType === "Employee") {
      if (location?.pathname == "/employment")
        setCurrentNavList(employeeSidebarNav);
      else setCurrentNavList(Innernav);
    } else if (ProfileDetails?.userType === "Patient") {
      setCurrentNavList(ResidentSidebar);
    }
  }, [ProfileDetails?.userType]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="Outer_sider_canvas_container"
      >
        <Offcanvas.Body className="Outer_sidebar_Body">
          <div className="close_btn">
            <i
              className="fa-solid fa-circle-xmark"
              onClick={() => handleClose()}
            ></i>
          </div>
          <figure className="flex  flex-col items-start">
            <span
              style={{
                lineHeight: ".1rem",
              }}
              className=""
            >
              {ProfileDetails?.adminId?.logo ? (
                <img
                  style={{
                    maxWidth: "155px",
                    maxHeight: "55px",
                    display: "block",
                    margin: "auto",
                  }}
                  src={getObjectUrlFromDownloadUrl(
                    ProfileDetails?.adminId?.logo
                  )}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    maxWidth: "155px",
                    maxHeight: "55px",
                    display: "block",
                    margin: "auto",
                  }}
                  src="/logo.png"
                  alt=""
                />
              )}
              <div className="text-sm mt-3" style={{ lineHeight: ".6rem" }}>
                <span>
                  <Button
                    style={{
                      backgroundColor: "#1A9FB2",
                      border: "2px solid white",
                      padding: "0 1.3rem",
                      color: "white",
                    }}
                    onClick={() => logOut()}
                  >
                    Sign Out
                  </Button>
                </span>
                <br />
              </div>
            </span>
          </figure>
          <nav className="py-6">
            {currentNavList.map((nav) => (
              <Link
                to={nav.link}
                key={nav.name}
                style={{ textDecoration: "none" }}
                onClick={() => navigate(nav.link)}
                className={location.pathname === nav.link && "active"}

              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".8rem",
                    marginBottom: "1.3rem",
                  }}
                >
                  <span
                    style={{
                      fontSize: ".5rem",
                      maxWidth: "30px",
                    }}
                  >
                    {/* <img src={nav.icon} alt="" /> */}
                    {typeof nav.icon === "string" ? (
                      <img src={nav.icon} alt="" />
                    ) : (
                      <nav.icon />
                    )}
                  </span>
                  <span
                    style={{
                      color: "white",
                      borderBottom: "none",
                      whiteSpace: "normal",
                      fontSize: "0.9rem",
                      textDecoration: "none",
                    }}
                  >
                    {nav.name}
                  </span>
                </div>
              </Link>
            ))}
          </nav>
          <h5 className="app-verion-text mb-0">App version 1.0</h5>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const DocumentUploaderEmployee = (props) => {
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [arr, setArr] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const removeFile = (index) => {
    const filterThis = arr?.filter((_, i) => index !== i);
    setArr(filterThis);
  };

  // File Upload
  const filePayload = new FormData();
  filePayload.append("file", file);
  filePayload.append("type", fileType);
  const uploadFiles = (e) => {
    e.preventDefault();
    uploadDocument({
      payload: filePayload,
      setArr,
      setLoading: setUploading,
    });
  };

  const payload = {
    data: arr,
  };

  const submitHandler = () => {
    const additionalFunctions = [props.onHide];
    createApi({
      url: `employee/createUploadDocumentForEmployee`,
      payload,
      successMsg: "Uploaded !",
      setLoading: setSubmitLoading,
      additionalFunctions,
    });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="file-upload-modal">
        <Container className="full-width-container">
          <form onSubmit={uploadFiles}>
            <div className="close-header">
              <h5>File Upload </h5>
              <i
                className="fa-solid fa-xmark"
                onClick={() => props.onHide()}
              ></i>
            </div>

            <div className="wrapper">
              <div className="flexbox">
                <div className="items">
                  <p className="head">Actions</p>
                  <button type="submit">
                    {uploading ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      "Add Additional files"
                    )}
                  </button>
                </div>
                <div className="items">
                  <p className="head">File Type</p>
                  {/* <select onChange={(e) => setFileType(e.target.value)}>
                    <option value=""> Select Prefrence </option>
                    {FilesNames?.map((i) => (
                      <option value={i} key={i}>
                        {" "}
                        {i}{" "}
                      </option>
                    ))}
                  </select> */}
                  <CustomSelect
                    options={FilesNames}
                    onChange={(value) => setFileType(value)}
                  />
                </div>
                <div className="items">
                  <p className="head">File Name</p>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>

              <table className="colored_table mt-3">
                <thead>
                  <tr>
                    <th className="text-start">Type</th>
                    <th className="text-start">File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {arr?.map((i, index) => (
                    <tr key={index}>
                      <td className="text-start"> {i.type} </td>
                      <td className="text-start">
                        <a href={i.document} target="_blank" rel="noreferrer">
                          View File
                        </a>
                      </td>
                      <td>
                        <div className="icon-joiner">
                          <span
                            className="del-btn cursor-pointer"
                            onClick={() => removeFile(index)}
                          >
                            <i className="fa-solid fa-trash-can" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="btn-container">
                <button
                  className="upload_files"
                  onClick={() => submitHandler()}
                  type="button"
                >
                  {submitLoading ? <ClipLoader color="#fff" /> : "Upload Files"}
                </button>
              </div>
            </div>
          </form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
