import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import {
  createApi,
  getApi,
  postApi,
  showNotification,
} from "../../Repository/Apis";
import parse from "html-react-parser";
import { ClipLoader } from "react-spinners";

const ChangePlan = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [response, setResponse] = useState({});
  const [otpRequested, setOtpRequested] = useState(false); // To track if OTP has been requested

  const handleSelect = (plan) => {
    if (plan === props.plan) {
      showNotification({
        message: `You are already subscribed to the ${plan} plan.`,
        type: "danger",
      });
      return;
    }
    setSelectedPlan(plan);
  };

  const fetchHandler = () => {
    getApi({
      url: `Pricing/getPricing`,
      setResponse: (data) => {
        // Ensure a consistent order for the plans
        const orderedPlans = data?.data?.sort((a, b) => {
          const order = ["Basic", "Growth"];
          return order.indexOf(a.name) - order.indexOf(b.name);
        });
        setPlans({ ...data, data: orderedPlans });
      },
    });
  };

  useEffect(() => {
    if (props?.show) {
      fetchHandler();
    }
  }, [props?.show]);

  const resetFields = () => {
    setOtp("");
    setSelectedPlan("");
    setOtpRequested(false);
  };

  const handleRequestOtp = async () => {
    if (!selectedPlan) {
      showNotification({
        message: "Please select a plan first.",
        type: "danger",
      });
      return;
    }

    try {
      setLoading(true)
      await getApi({
        url: `stripe/subscription/change-plan-request?tier=${selectedPlan}`,
        setLoading,
        setResponse,
      });
      setLoading(false)
      setOtpRequested(true);
      showNotification({ message: "OTP sent successfully.", type: "success" });
    } catch (error) {
      setLoading(false)
      console.log("error", error);

      const msg = error?.response?.data?.message || "Failed to request OTP.";
      showNotification({ message: msg, type: "danger" });
    }
  };

  const handleResendOtp = async () => {
    try {
      await getApi({
        url: `stripe/subscription/change-plan-request?tier=${selectedPlan}`,
        setLoading,
        setResponse,
      });
      setOtp("")
      showNotification({
        message: "OTP resent successfully.",
        type: "success",
      });
    } catch (error) {
      const msg = error?.response?.data?.message || "Failed to resend OTP.";
      showNotification({ message: msg, type: "danger" });
    }
  };

  const submitHandler = async () => {
    if (!otp) {
      showNotification({ message: "Please enter the OTP.", type: "danger" });
      return;
    }

    try {   
     const res = await createApi({
        setLoading,
        url: `stripe/subscription/confirm-plan`,
        payload: { otp },
      });
      //   showNotification({ message: "Subscription plan changed successfully.", type: "success" });
      if(res?.success) {
        resetFields();
        props.onHide();
      }
      
    } catch (error) {
      console.log("error", error);
      const msg =
        error?.response?.data?.message || "Failed to change subscription plan.";
      showNotification({ message: msg, type: "danger" });
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        resetFields();
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <h5 className="fw-bold mb-0">Choose Your Subscription Plan</h5>
      </Modal.Header>
      <ModalBody>
        <Row>
          {plans?.data?.map((plan) => (
            <Col key={plan.name} md={6} className="mb-4">
              <Card
                className={`h-100 upgrade-plan-card mt-4 ${plan.name ===  props.plan ? "select-plan-card" : "unselect-plan-card"
                  }`}
                style={{
                  borderColor: selectedPlan === plan.name ? "#006DA0" : "#cccccc",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <Card.Body className="py-4">
                {plan.name ===  props.plan &&   <span className="select-plan-badge">Current Plan</span>}
                  <Card.Title className="fw-bold text-center">{plan.name}</Card.Title>
                  <h4 className="plan-per-month text-center">{`$${plan.perUser}/month`}</h4>
                  {/* <div
                    className="text-start mt-3"
                    dangerouslySetInnerHTML={{ __html: plan.details[0] }}
                  /> */}
                  {parse(plan.details?.[0])}
                  <Button
                    className="select-plan-btn"
                    variant={
                      selectedPlan === plan.name
                        ? "primary"
                        : "light"
                    }
                    onClick={() => handleSelect(plan.name)}
                    disabled={plan.name === props.plan} 
                    styled={{ color: (plan.name ===  props.plan ? "white": "black") }}
                  >
                    {selectedPlan === plan.name
                      ? "Selected"
                      :  props.plan ==="Basic"? "Upgrade Plan" :"Downgrade Plan"}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {otpRequested && (
          <>
            <Row>
              <Form.Group className="mb-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="fw-bold">Enter OTP</Form.Label>
                  <button
                    style={{ color: "#68BFCC" }}
                    onClick={handleResendOtp}
                    className="resend-otp-btn mb-2"
                    type="button"
                  >
                    Resend OTP
                  </button>
                </div>

                <Form.Control
                  type="text"
                  value={otp}
                  onChange={(e) => {
                    const value = e.target.value?.trim();
                    if (/^\d*$/.test(value)) {
                      setOtp(value);
                    }
                  }}
                  placeholder="Enter OTP"
                />
              </Form.Group>
            </Row>
          </>
        )}
        <Row className="justify-content-center mt-3">
          <Col md={12} className="text-center">
            <Button
              className="theme-button"
              onClick={otpRequested ? submitHandler : handleRequestOtp}
            >
              {otpRequested ? "Confirm Plan Change" : loading ? <ClipLoader color="#fff" /> : "Request OTP"}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ChangePlan;
