/** @format */

import React, { useState, useEffect } from "react";
// import io from "socket.io-client";
import {
  createApi,
  createFirebaseDocument,
  getApi,
  getObjectUrlFromDownloadUrl,
} from "../../Repository/Apis";
import { Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { setDocumentID } from "../../store/chatSlice";
import { CreateGroup } from "../../Canvas/Canvases";
import { fetchPaitentName } from "../../utils/utils";
import { defaultUserImg } from "../../assets";
import "../Chat/Chat.css";
import { getSocket } from "../../socket";
const socket = getSocket();

const CreateChat = ({
  handleClose,
  show,
  setUserType,
  chatType,
  chatListHandler,
}) => {

  const [allEmployees, setAllEmployees] = useState({});
  const [allPatients, setAllPatients] = useState({});
  const [limit, setLimit] = useState(25);
  const [patientLimit, setPatientLimit] = useState(25);
  const [patientLoading, setPatientLoading] = useState(false);
  const [allGuardians, setAllGuardians] = useState({});
  const [guardianLoading, setGuardianLoading] = useState(false);
  const [guardianLimit, setGuardianLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const ProfileDetails = useSelector(userProfile);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      getApi({
        url: `admin/getUserForChat?userType=Employee&limit=${limit}`,
        setResponse: setAllEmployees,
        setLoading,
      });
      getApi({
        url: `admin/getUserForChat?userType=Patient&limit=${patientLimit}`,
        setResponse: setAllPatients,
        setLoading: setPatientLoading,
      });
      getApi({
        url: `admin/getUserForChat?userType=Guardian&limit=${guardianLimit}`,
        setResponse: setAllGuardians,
        setLoading: setGuardianLoading,
      });
    }
  }, [limit, patientLimit, guardianLimit, show]);

  // useEffect(() => {
  //   socket.on("updateUserList", (updatedUsers) => {
  //     setAllEmployees(updatedUsers.employees);
  //     setAllPatients(updatedUsers.patients);
  //   });
  //   return () => {
  //     // socket.off("updateUserList");
  //   };
  // }, []);

  const hasMore =
    allEmployees?.data?.totalDocs > allEmployees?.data?.docs?.length;
  const hasMorePatient =
    allPatients?.data?.totalDocs > allPatients?.data?.docs?.length;
  const hasMoreGuardian =
    allGuardians?.data?.totalDocs > allGuardians?.data?.docs?.length;

  const loadMore = () => {
    if (limit < allEmployees?.data?.totalDocs) {
      setLimit(limit + 25);
    }
  };

  const loadMorePatient = () => {
    if (patientLimit < allPatients?.data?.totalDocs) {
      setPatientLimit(patientLimit + 25);
    }
  };
  const loadMoreGuardian = () => {
    if (guardianLimit < allGuardians?.data?.totalDocs) {
      setPatientLimit(guardianLimit + 25);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: loading,
  });
  const [patientRef] = useInfiniteScroll({
    loading: patientLoading,
    hasNextPage: hasMorePatient,
    onLoadMore: loadMorePatient,
    disabled: patientLoading,
  });
  const [guardianRef] = useInfiniteScroll({
    loading: guardianLoading,
    hasNextPage: hasMoreGuardian,
    onLoadMore: loadMoreGuardian,
    disabled: guardianLoading,
  });

  // const createDocument = ({ collectionName, navigationLink, recipientObj }) => {
  //   const payload = { recipient: recipientObj, sender: ProfileDetails, text: [] };
  //   dispatch(createFirebaseDocument({ payload, collectionName, navigationLink, handleClose, recipientName: recipientObj }));
  //   socket.emit("newChat", payload);
  // };

  const CreateChat = async (id) => {
    try {
      const res = await createApi({
        url: `chat/add-conversation/${id}`,
        setLoading,
        payload: {},
        showAlert: false,
        navigate: navigate(
          ProfileDetails?.userType === "Guardian" ||
            ProfileDetails?.userType === "Patient"
            ? "/chatPatient"
            : "/chat"
        ),
      });
      handleClose();
      chatListHandler();
      if (res?.data) {
        const emitData = {
          type: "CONVERSATION",
          id: res?.data?.conversation?._id,
        };
        socket.emit("join-room", JSON.stringify(emitData));
      }
    } catch (error) {
      console.error("Error creating chat:", error);
    }
    handleClose();
    chatListHandler();
  };

  const openGroup = () => {
    handleClose();
    setOpen(true);
    setUserType("Group");
  };

  const filterdEmployees = allEmployees?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );
  const filterdPatients = allPatients?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );
  const filterdGuardian = allGuardians?.data?.docs?.filter(
    (i) => i._id !== ProfileDetails?._id
  );

  return (
    <>
      <CreateGroup show={open} handleClose={() => setOpen(false)} chatListHandler={chatListHandler} />
      <Modal show={show} onHide={handleClose} placement="end">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Create New Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {chatType === "Group" && (
            <div className="employee-btn-joiner">
              <button
                type="button"
                className="employee_create_btn"
                onClick={openGroup}
              >
                Create New Group
              </button>
            </div>
          )}

          {chatType === "Employee" && filterdEmployees?.length > 0 && (
            <>
              <h5 className="fw-bold">
                Employees ({filterdEmployees?.length})
              </h5>
              <div className="create-new-chat-room">
                {filterdEmployees?.map((i, index) => (
                  <div
                    className="select-employee"
                    key={`user${index}`}
                    // onClick={() => createDocument({ recipientObj: i, collectionName: "EmployeeChat", navigationLink: "/chat" })}
                    onClick={() => CreateChat(i?._id)}
                  >
                    <div className="group-chat-info">
                      <img
                        src={
                          i.profilePic
                            ? getObjectUrlFromDownloadUrl(i.profilePic)
                            : defaultUserImg
                        }
                        className="original-img"
                        alt=""
                      />
                      <div className="content">
                        <p className="heading text-start">
                          {fetchPaitentName(i)}
                        </p>
                        <p className="faded">{i.mobileNumber}</p>
                        <p className="faded">{i.email}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {loading && <ClipLoader />}
                <div ref={sentryRef}></div>
              </div>
            </>
          )}

          {chatType === "Patient" && filterdPatients?.length > 0 && (
            <>
              <h5 className="fw-bold">Residents ({filterdPatients?.length})</h5>
              <div className="create-new-chat-room">
                {filterdPatients?.map((i, index) => (
                  <div
                    className="select-employee"
                    key={`patient${index}`}
                    // onClick={() => createDocument({ recipientObj: i, collectionName: "PatientChat", navigationLink: "/chat" })}
                    onClick={() => CreateChat(i?._id)}
                  >
                    <div className="group-chat-info">
                      <img
                        src={
                          i.profilePic
                            ? getObjectUrlFromDownloadUrl(i.profilePic)
                            : defaultUserImg
                        }
                        className="original-img"
                        alt=""
                      />
                      <div className="content">
                        <p className="heading text-start">
                          {fetchPaitentName(i)}
                        </p>
                        <p className="faded">{i.mobileNumber}</p>
                        <p className="faded">{i.email}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {patientLoading && <ClipLoader />}
                <div ref={patientRef}></div>
              </div>
            </>
          )}

          {chatType === "Guardian" && filterdGuardian?.length > 0 && (
            <>
              <h5 className="fw-bold">Guardian ({filterdGuardian?.length})</h5>
              <div className="create-new-chat-room">
                {filterdGuardian?.map((i, index) => (
                  <div
                    className="select-employee"
                    key={`guardian${index}`}
                    // onClick={() => createDocument({ recipientObj: i, collectionName: "PatientChat", navigationLink: "/chat" })}
                    onClick={() => CreateChat(i?._id)}
                  >
                    <div className="group-chat-info">
                      <img
                        src={
                          i.profilePic
                            ? getObjectUrlFromDownloadUrl(i.profilePic)
                            : defaultUserImg
                        }
                        className="original-img"
                        alt=""
                      />
                      <div className="content">
                        <p className="heading text-start">
                          {fetchPaitentName(i)}
                        </p>
                        <p className="faded">{i.mobileNumber}</p>
                        <p className="faded">{i.email}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {guardianLoading && <ClipLoader />}
                <div ref={guardianRef}></div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateChat;
