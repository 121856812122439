/** @format */
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { OuterSidebar } from "../../../Mod/Modal";
import { NotificationToast } from "../../../Canvas/Canvases";
import { defaultProfileIcon } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { updateUnreadMessageCount, updateUnreadNotificationCount, userProfile } from "../../../store/authSlice";
import { getApi, getObjectUrlFromDownloadUrl, LogOutHandler } from "../../../Repository/Apis";
import { getAllData } from "../../../admin/MainComponent/api";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdNotificationsNone, MdManageAccounts, MdChatBubbleOutline, MdLogout } from "react-icons/md";
import { getSocket } from "../../../socket";
const socket = getSocket();
const Navbar = ({routesMob}) => {
  const logOut = () => {
    dispatch(LogOutHandler(navigate));
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showA, setShowA] = useState(false);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState({});
  const [show, setShow] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [notifications, setNotifications] = useState({});
  const [mainNotifications, setMainNotifications] = useState({});
  const ProfileDetails = useSelector(userProfile);
  const {unreadMessages, unreadNotifications} = useSelector((state)=>state.auth) || {};
  const getAllNotifications = () => {
 
    getAllData("admin/allNotification").then((res) => {
      const today = new Date().setHours(0, 0, 0, 0); // Setting today's date without time
      const todayAndFutureNotifications = res.data.data?.filter((i) => {
        const notificationDate = new Date(`${i?.date} ${i?.time}`).setHours(0, 0, 0, 0);
        return notificationDate >= today; // Include today and future dates
      });

      setNotifications(todayAndFutureNotifications?.slice(0, 2));

      const newData = todayAndFutureNotifications?.filter(
        (i) => i?.title && i.title.trim() !== ""
      );

      const newDate2 = newData?.map((i) => i?.title);
      if (newDate2 && newDate2?.length > 0) {
        const lastData = newDate2[newDate2.length - 1];
        setMainNotifications(lastData);
      }
    }).catch((err) => console.log(err));;

  };


  const notify = () => {
    setShowA(!showA);
  };

  const debouncedSetQuery = (term) => {
    clearTimeout(debouncedSetQuery.timeoutId);
    debouncedSetQuery.timeoutId = setTimeout(() => {
      setQuery(term);
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1179) {
        setOpen(false);
      }
    };
  
    window.addEventListener("resize", handleResize);
    handleResize();
    socket.on('new-notification', (notification)=>{
      dispatch(updateUnreadNotificationCount({count: 1, read: false}))
    })
    socket.on('new-message', (message)=>{
      dispatch(updateUnreadMessageCount({count: 1, read: false}))
    })
    return () => {
      socket.off("new-notification");
      socket.off("new-message");
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const fetchHandler = useCallback(() => {
    getApi({
      url: `employee/getPatient?limit=100&page=1&searchQuery=${query}`,
      setResponse: setUsers,
    });
  }, [query]);

  useEffect(() => {
    if (query) {
      fetchHandler();
      setShow(true);
    }
  }, [query, fetchHandler]);

  useEffect(() => {
    ProfileDetails?.userType === "Admin" ?
      getAllNotifications() :
      getApi({
        url: `employee/allNotification`,
        setResponse: setNotifications,
      });
  }, []);

  useEffect(() => {
    if (searchActive) {
      setShow(true);
    }
  }, [searchActive]);

  const showSearch = show === true && users?.data?.docs?.length > 0;
  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShow(false);
        setSearchActive(false);
      }
    };

    document.documentElement.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.documentElement.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 

  
  return (
    <>
      <OuterSidebar show={open} handleClose={() => setOpen(false)} routesMob={routesMob} />
      <NotificationToast
        setShow={setShowA}
        show={showA}
        notifications={notifications}
        setNotifications={setNotifications}
        handleClose={toggleShowA}
      />

      {show && <div id="nav-cover"></div>}

      <div className={"Main_Nav"}>
        {/* <p className="Employee_title">
            Welcome Back {ProfileDetails && fetchPaitentName(ProfileDetails)}! 
          {" "}
          How can we help you today?
          <div>
          {mainNotifications?.length > 0 && (
            <Typewriter
              options={{
                strings: `${mainNotifications}`,
                autoStart: true,
                loop: true,
              }}
            />
          )}
        </div>
        </p> */}

        <div className="top-nav-bar d-flex align-items-center justify-content-between">
          <div className="nav-left-bar d-flex align-items-center gap-2">
            <div className="side-toggle-btn me-2 cursor-pointer">
              <i className="fa-solid fa-bars" style={{fontSize:"18px"}} onClick={() => setOpen(!open)}></i>
            </div>
            <Link to="/Home" style={{ textDecoration: "none" }}>
              {ProfileDetails?.adminId?.logo || ProfileDetails?.logo ? (
                <img
                  style={{
                    maxWidth: "auto",
                    width: "auto",  
                    maxHeight: "54px",
                  }}
                  src={getObjectUrlFromDownloadUrl(ProfileDetails?.adminId?.logo || ProfileDetails?.logo)}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    maxWidth: "auto",
                    width: "auto",
                    maxHeight: "54px",
                  }}
                  src="/logo.png"
                  alt=""
                />
              )}
            </Link>
          </div>
          <div className="nav-right-bar d-flex align-items-center gap-2">
            <div className="navbar-icons">
              {ProfileDetails?.adminId?.permissionChat === true && (ProfileDetails.userType === "Employee" || ProfileDetails.userType === "Patient" || ProfileDetails.userType === "Guardian") && (
                // <Image
                //   src="/Navbar/chat.png"
                //   className="navbar-notify-image"
                //   onClick={() => navigate(ProfileDetails.userType === "Employee" ? "/chat" : "/chatPatient")}
                // />
                <Link  to={ProfileDetails.userType === "Employee" ? "/chat" : "/chatPatient"} className="navbar-links-icons" 
                // onClick={() => navigate(ProfileDetails.userType === "Employee" ? "/chat" : "/chatPatient")}
                >
                 {!!unreadMessages && <span className="notify-indicator"></span> }
                  <MdChatBubbleOutline className="nav-menu-icon" />
                </Link>
              )}

              {/* <Image
                src={notifications?.data?.length > 0 ? "/notify.svg" : "/nonotify.png"}
                className="navbar-notify-image"
                onClick={notify}
              /> */}
              <Link to={"#"} className="navbar-links-icons" onClick={notify}>
               {!!unreadNotifications && <span className="notify-indicator"></span> }
                <MdNotificationsNone className="nav-menu-icon" />
              </Link>
            </div>
            <Dropdown className="user-profile-dropdown">
              <Dropdown.Toggle className="profile-dropdown-btn" id="dropdown-user">
                <div className="nav-user d-flex align-items-center">
                  <div className="nav-user-image flex-shrink-0 me-sm-2">
                    {ProfileDetails?.profilePic ? (
                      <img
                        src={getObjectUrlFromDownloadUrl(ProfileDetails?.profilePic)}
                        alt=""
                        className="profile_img"
                      // onClick={() => navigate("/profile")}
                      />
                    ) : (
                      <img
                        src={defaultProfileIcon}
                        alt=""
                        className="profile_img"
                      // onClick={() => navigate("/profile")}
                      />
                    )}
                  </div>
                  <div className="nav-user-name d-none d-lg-inline-block">
                    {ProfileDetails?.firstName ?? ""}{" "}
                    {ProfileDetails?.middleName ?? ""}{" "}
                    {ProfileDetails?.lastName ?? ""}{" "}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropdown-menu">
                <Dropdown.Item href="#">
                  <div className="nav-user d-flex align-items-center">
                    <div className="nav-user-image flex-shrink-0 me-2">
                      {ProfileDetails?.profilePic ? (
                        <img
                          src={getObjectUrlFromDownloadUrl(ProfileDetails?.profilePic)}
                          alt=""
                          className="profile_img"
                        />
                      ) : (
                        <img
                          src={defaultProfileIcon}
                          alt=""
                          className="profile_img"
                        />
                      )}
                    </div>
                    <div className="username-info">
                      <div className="nav-user-name">
                        {ProfileDetails?.firstName ?? ""}{" "}
                        {ProfileDetails?.middleName ?? ""}{" "}
                        {ProfileDetails?.lastName ?? ""}{" "}
                      </div>
                      <div className="nav-user-email">{ProfileDetails?.email ?? ""}{" "}</div>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#" onClick={() => navigate("/profile")}>
                  <div className="d-flex align-items-center">
                    <MdManageAccounts className="me-2 fs-md" />
                    <span className="d-inline-block">Edit Profile Details</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#" onClick={() => logOut()} >
                  <div className="d-flex align-items-center">
                    <MdLogout className="me-2 fs-md" />
                    <span className="d-inline-block">Logout</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
