/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { decryptData, encryptData } from "../utils/utils";
const storedUser = localStorage.getItem("user");
const savedData = storedUser ? decryptData(storedUser, true)?.userType : null;
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: savedData ? true : false,
    userProfile: savedData ? savedData : {},
    firebaseUser: {},
    unreadMessages: 0,
    unreadNotifications: 0,
  },
  reducers: {
    LoginSlice: (state, action) => {
      state.isAuthenticated = true;
      state.userProfile = action?.payload?.profile?.data;
      state.unreadNotifications =
        action?.payload?.profile?.data?.unreadNotifications || 0;
      state.unreadMessages =
        action?.payload?.profile?.data?.totalUnreadMessages || 0;
      localStorage.setItem("user", encryptData(action?.payload?.profile?.data));

      localStorage.setItem(
        "token",
        encryptData(action?.payload?.profile?.accessToken)
      );
      localStorage.setItem(
        "user-timestamp",
        encryptData(action?.payload?.timeStamp)
      );
    },
    updateUnreadMessageCount: (state, action) => {
      const { read, count } = action.payload;
      if (read) {
        state.unreadMessages = Math.max(0, state.unreadMessages - count); // Prevents negative values
      } else {
        state.unreadMessages += count;
      }
    },
    updateUnreadNotificationCount: (state, action) => {
      const { read, count } = action.payload;
      if (read) {
        state.unreadNotifications = Math.max(0, state.unreadNotifications - count); // Prevents negative values
      } else {
        state.unreadNotifications += count;
      }
    },
   
    UpdateUserProfile: (state, action) => {
      state.userProfile = action?.payload;
      localStorage.setItem("user", encryptData(action?.payload));
    },
    SetFirebaseUser: (state, action) => {
      state.firebaseUser = action.payload;
    },
    LOGOUT: (state) => {
      state.isAuthenticated = false;
      state.userProfile = {};
      state.firebaseUser = {};
      localStorage.clear();
    },
  },
});

export const {
  LoginSlice,
  LOGOUT,
  UpdateUserProfile,
  SetFirebaseUser,
  updateUnreadMessageCount,
  updateUnreadNotificationCount,
} = authSlice.actions;

export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const userProfile = (state) => state.auth.userProfile;
export const firebaseUser = (state) => state.auth.userProfile;

export default authSlice.reducer;
