import React, { lazy } from 'react';

const lazyWithRetry = (importFunc) => {
  return lazy(() =>
    importFunc().catch((error) => {
      if (error.name === "ChunkLoadError") {
        window.location.reload(); 
      }
      return Promise.reject(error);
    })
  );
};
// Lazy load the components

// Admin Routing
const Contacts = lazyWithRetry(() => import('../admin/pages/Dashboard/Contacts'))
const ActivityLog = lazyWithRetry(() => import('../admin/pages/Dashboard/ActivityLog'))
const EmployeeTerminationlist = lazyWithRetry(() => import('../admin/pages/Dashboard/EmployeeTerminationlist'))
const ViewEmployeeTermination = lazyWithRetry(() => import('../admin/pages/Dashboard/ViewEmployeeTermination'))
const EmployeePerformanceEditAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/EmployeePerformanceEditAdmin'))
const UpdateEmployeeTermination = lazyWithRetry(() => import('../admin/pages/Dashboard/UpdateEmployeeTermination'))
const EmployeeTermination = lazyWithRetry(() => import('../admin/pages/Dashboard/EmployeeTermination'))
const MultiEmployeeTrackingList = lazyWithRetry(() => import('../Pages/Employee Tracking/MultiEmployeeTrackingList'))
const CreateMultiEmployeeTracking = lazyWithRetry(() => import('../Pages/Employee Tracking/CreateMultiEmployeeTracking'))
const Logs = lazyWithRetry(() => import('../admin/pages/Dashboard/Logs'))
const Tracking = lazyWithRetry(() => import('../admin/pages/Dashboard/Tracking'))
const ReferenceCheckAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/ReferenceCheck'))
// const PTORequest = lazyWithRetry(()=>import ('../admin/pages/Dashboard/PTORequest'))
const EmployeePerformanceAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/EmployeePerformance'))
const CalenderSchedule = lazyWithRetry(() => import('../admin/pages/Dashboard/Calandar'))
const ActivityScheduleAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/ActivitySchedule'))
const Notifications = lazyWithRetry(() => import('../admin/pages/Dashboard/Notifications'))
const JobDescriptionAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/JobDescription'))
const OfferLater = lazyWithRetry(() => import('../admin/pages/Dashboard/OfferLater'))
const PatientVitals = lazyWithRetry(() => import('../admin/pages/Dashboard/PatientVitals'))
const NotesLibrary2 = lazyWithRetry(() => import('../admin/pages/Dashboard/NotesLibrary2'))
const PatinentTracking = lazyWithRetry(() => import('../admin/pages/Dashboard/PatinentTracking'))
const HomePage1 = lazyWithRetry(() => import('../admin/pages/HomePage1'))
const StaffSchedule2 = lazyWithRetry(() => import('../admin/pages/Dashboard/StaffSchedule2'))
// const DashboardAdmin = lazyWithRetry(() => import("../admin/pages/Dashboard"));
const AdminLogo = lazyWithRetry(() => import("../admin/pages/Dashboard/AdminLogo"));
const EmployeeList = lazyWithRetry(() => import("../admin/pages/Dashboard/EmployeeList"));
const EmployeeData = lazyWithRetry(() => import("../admin/pages/Dashboard/EmployeeData"));
// const CreateI9 = lazyWithRetry(()=>import ('../Pages/EmploymentInformation/I-9/CreateI9'))
// const CreateLrc = lazyWithRetry(()=>import ('../Pages/EmploymentInformation/LRC-1031/CreateLrc'))
// const CreateFw9 = lazyWithRetry(()=>import ('../Pages/EmploymentInformation/FW9/CreateFw9'))
// const CreateFw4 = lazyWithRetry(()=>import ('../Pages/EmploymentInformation/FW-4/CreateFw4'))
// const Create2023 = lazyWithRetry(()=>import ('../Pages/EmploymentInformation/2023Forms/Create2023'))
const AllI9 = lazyWithRetry(() => import('../Pages/EmploymentInformation/I-9/AllI9'))
const AllFW9 = lazyWithRetry(() => import('../Pages/EmploymentInformation/FW9/AllFW9'))
const AllFW4 = lazyWithRetry(() => import('../Pages/EmploymentInformation/FW-4/AllFW4'))
const AllLRC = lazyWithRetry(() => import('../Pages/EmploymentInformation/LRC-1031/AllLRC'))
const All2023 = lazyWithRetry(() => import('../Pages/EmploymentInformation/2023Forms/All2023'))
const AllAppendix = lazyWithRetry(() => import('../Pages/EmploymentInformation/Appendix/AlllAppendix'))
const OfferletterAdmin = lazyWithRetry(() => import('../admin/pages/Dashboard/OfferletterAdmin'))

// Employee Routing
const CreateRefusal = lazyWithRetry(() => import("../Pages/Medication/Refusal/CreateRefusal"));
const InitialAssismentList = lazyWithRetry(() => import('../Pages/Intake/InitalAssisment/InitialAssismentList'))
const ViewEmploymentApplicationForm = lazyWithRetry(() => import('../Pages/Employment Application/ViewEmploymentApplicationForm'))
// const AssignedPatient = lazyWithRetry(() => import("../Pages/Assigned_Patient/Assigned_Patient"));
// const Profile = lazyWithRetry(() => import("../Pages/Profile/Profile"));
const Personal = lazyWithRetry(() => import("../Pages/EmploymentInformation/Peronal_Information/Personal"));
const OfferLetter = lazyWithRetry(() => import("../Pages/EmploymentInformation/OfferLetter/OfferLetter"));
const SignOfferLetter = lazyWithRetry(() => import("../Pages/EmploymentInformation/OfferLetter/SignOfferLetter"));
const SignReferenceCheck = lazyWithRetry(() => import("../Pages/EmploymentInformation/ReferenceCheck/EditRefrenceCheck"));
const Appendix = lazyWithRetry(() => import("../Pages/EmploymentInformation/Appendix/Appendix"));
const Forms2023 = lazyWithRetry(() => import("../Pages/EmploymentInformation/2023Forms/2023Forms"));
// const ReferenceCheck = lazyWithRetry(() => import("../Pages/EmploymentInformation/ReferenceCheck/ReferenceCheck"));
const LRC1031 = lazyWithRetry(() => import("../Pages/EmploymentInformation/LRC-1031/LRC-1031"));
const APS = lazyWithRetry(() => import("../Pages/EmploymentInformation/APS/APS"));
const JobDescription = lazyWithRetry(() => import("../Pages/EmploymentInformation/JobDescription/JobDescription"));
const FW4Form = lazyWithRetry(() => import("../Pages/EmploymentInformation/FW-4/FW-4"));
const I9Form = lazyWithRetry(() => import("../Pages/EmploymentInformation/I-9/I9Form"));
const FW9 = lazyWithRetry(() => import("../Pages/EmploymentInformation/FW9/FW9"));
const GroupNotes = lazyWithRetry(() => import("../Pages/Group Notes/GroupNotes"));
const Traning = lazyWithRetry(() => import("../Pages/Training/Traning"));
const OnSite = lazyWithRetry(() => import("../Pages/Training/OnSite/OnSite"));
const Skills = lazyWithRetry(() => import("../Pages/Training/SkillTraining/Skills"));
const TimeOfRequest = lazyWithRetry(() => import("../Pages/Time Off Request/TimeOfRequest"));
const Medications = lazyWithRetry(() => import("../Pages/Medication/Medications"));
const MedicationCount = lazyWithRetry(() => import("../Pages/Medication/Count/MedicationCount"));
const InformedConsent = lazyWithRetry(() => import("../Pages/Medication/InformedConsent/InformedConsent"));
const PRNform = lazyWithRetry(() => import("../Pages/Medication/MedicationLog/PRNform"));
const PatientTracking = lazyWithRetry(() => import("../Pages/Patient Tacking/PatientTracking"));
const PatientChart = lazyWithRetry(() => import("../Pages/PatientChart/PatientMenu/PatientChartWrapper"));
const Discharge = lazyWithRetry(() => import("../Pages/PatientChart/Discharge/Discharge"));
const EmployeePerformance = lazyWithRetry(() => import("../Pages/Employee Performance/EmployeePerformance"));
const EmployeeTracking = lazyWithRetry(() => import("../Pages/Employee Tracking/EmployeeTracking"));
const TimeSheet = lazyWithRetry(() => import("../Pages/Time Sheet/TimeSheet"));
const TimeSheetView = lazyWithRetry(() => import("../Pages/Time Sheet/TimeSheetView"));
const PatientList = lazyWithRetry(() => import("../Pages/Patients/PatientListWrapper"));
const Vitals2 = lazyWithRetry(() => import("../Pages/PatientVitals/Vitals2"));
const EditVitals = lazyWithRetry(() => import("../Pages/PatientVitals/EditVitals"));
const OnSite2 = lazyWithRetry(() => import("../Pages/Training/OnSite/OnSite2"));
const Skills2 = lazyWithRetry(() => import("../Pages/Training/SkillTraining/skills2"));
const Reconciliations2 = lazyWithRetry(() => import("../Pages/Medication/Reconciliation/Reconsilations2"));
const MedicautionCount2 = lazyWithRetry(() => import("../Pages/Medication/Count/MedicautionCount2"));
const InformedConsent2 = lazyWithRetry(() => import("../Pages/Medication/InformedConsent/InformedConsent2"));
const PRNform2 = lazyWithRetry(() => import("../Pages/Medication/MedicationLog/PRNform2"));
const EditProgressNote = lazyWithRetry(() => import("../Pages/PatientChart/Progress/EditProgressNote"));
const UpdateIncidentReport = lazyWithRetry(() => import("../Pages/PatientChart/IncidentReport/UpdateIncidentReport"));
const UpdateDischarge = lazyWithRetry(() => import("../Pages/PatientChart/Discharge/UpdateDischarge"));
const UpdatePrn = lazyWithRetry(() => import("../Pages/Medication/MedicationLog/UpdatePrn"));
const UpdateInform = lazyWithRetry(() => import("../Pages/Medication/InformedConsent/UpdateInform"));
const UpdateCount = lazyWithRetry(() => import("../Pages/Medication/Count/UpdateCount"));
const ViewCount = lazyWithRetry(() => import("../Pages/Medication/Count/ViewCount"));
const ViewInform = lazyWithRetry(() => import("../Pages/Medication/InformedConsent/ViewInform"));
const Employment = lazyWithRetry(() => import("../Pages/EmploymentInformation/EmplymentMenu/Employment"));
const ProgressNote2 = lazyWithRetry(() => import("../Pages/PatientChart/Progress/ProgressNote2"));
const ProgressNote = lazyWithRetry(() => import("../Pages/PatientChart/Progress/ProgressNote"));
const PersonalInfo = lazyWithRetry(() => import("../Pages/EmploymentInformation/Peronal_Information/PersonalInfo"));
const ViewInfo = lazyWithRetry(() => import("../Pages/EmploymentInformation/Peronal_Information/ViewInfo"));
const AllAps = lazyWithRetry(() => import("../Pages/EmploymentInformation/APS/AllAps"));
const EditAPS = lazyWithRetry(() => import("../Pages/EmploymentInformation/APS/EditAPS"));
const Dashboard = lazyWithRetry(() => import("../Pages/Dashboard/Dashboard"));
const SearchPageWrapper = lazyWithRetry(() => import("../Pages/Search/SearchPageWrapper"));
const BasicInformation = lazyWithRetry(() => import("../Pages/Employment Application/BasicInformation"));
const EducationalBackground = lazyWithRetry(() => import("../Pages/Employment Application/EducationalBackground"));
const EmploymentHistory = lazyWithRetry(() => import("../Pages/Employment Application/EmploymentHistory"));
const OtherInformation = lazyWithRetry(() => import("../Pages/Employment Application/OtherInformation"));
const Acknowledgement = lazyWithRetry(() => import("../Pages/Employment Application/Acknowledgement"));
const Vitals = lazyWithRetry(() => import("../Pages/PatientVitals/Vitals"));
const EditOnSite = lazyWithRetry(() => import("../Pages/Training/OnSite/EditOnSite"));
const ServiceLog = lazyWithRetry(() => import("../Pages/Training/ServiceLog/ServiceLog"));
const CreateServiceLog = lazyWithRetry(() => import("../Pages/Training/ServiceLog/CreateServiceLog"));
const EditServiceLog = lazyWithRetry(() => import("../Pages/Training/ServiceLog/EditServiceLog"));
// const NotFound = lazyWithRetry(() => import("../Pages/404/NotFound"));
const BookAppointment = lazyWithRetry(() => import("../Pages/Patients/BookAppointment"));
const ViewSite = lazyWithRetry(() => import("../Pages/Training/OnSite/ViewSite"));
const ViewServiceLog = lazyWithRetry(() => import("../Pages/Training/ServiceLog/ViewServiceLog"));
const EditSkill = lazyWithRetry(() => import("../Pages/Training/SkillTraining/EditSkill"));
const ViewSkills = lazyWithRetry(() => import("../Pages/Training/SkillTraining/ViewSkills"));
const GetTimeOfRequest = lazyWithRetry(() => import("../Pages/Time Off Request/GetTimeOfRequest"));
const ViewTimeOfRequest = lazyWithRetry(() => import("../Pages/Time Off Request/ViewTimeOfRequest"));
const Schedule = lazyWithRetry(() => import("../Pages/Time Sheet/Schedule"));
const ViewAps = lazyWithRetry(() => import("../Pages/EmploymentInformation/APS/ViewAps"));
const CreateDischarge = lazyWithRetry(() => import("../Pages/PatientChart/Discharge/CreateDischarge"));
const ViewDischarge = lazyWithRetry(() => import("../Pages/PatientChart/Discharge/ViewDischarge"));
const DTF = lazyWithRetry(() => import("../Pages/PatientChart/Activities/DTF"));
const CreateDTF = lazyWithRetry(() => import("../Pages/PatientChart/Activities/CreateDTF"));
const UpdateDTF = lazyWithRetry(() => import("../Pages/PatientChart/Activities/UpdateDTF"));
const ViewDTF = lazyWithRetry(() => import("../Pages/PatientChart/Activities/ViewDTF"));
const FinancialRecord = lazyWithRetry(() => import("../Pages/PatientChart/FinancialRecord/FinancialRecord"));
const CreateRecord = lazyWithRetry(() => import("../Pages/PatientChart/FinancialRecord/CreateRecord"));
const EditFinancialRecord = lazyWithRetry(() => import("../Pages/PatientChart/FinancialRecord/EditFinancialRecord"));
const ViewFinancialRecord = lazyWithRetry(() => import("../Pages/PatientChart/FinancialRecord/ViewFinancialRecord"));
const StaffNotes = lazyWithRetry(() => import("../Pages/PatientChart/StaffNote/StaffNotes"));
const CreateStaffNote = lazyWithRetry(() => import("../Pages/PatientChart/StaffNote/CreateStaffNote"));
const UpdateStaffNote = lazyWithRetry(() => import("../Pages/PatientChart/StaffNote/UpdateStaffNote"));
const ViewStaffNote = lazyWithRetry(() => import("../Pages/PatientChart/StaffNote/ViewStaffNote"));
const IncidentReport = lazyWithRetry(() => import("../Pages/PatientChart/IncidentReport/IncidentReport"));
const CreateIncident = lazyWithRetry(() => import("../Pages/PatientChart/IncidentReport/CreateIncident"));
const ContactNote = lazyWithRetry(() => import("../Pages/PatientChart/ContactNote/ContactNote"));
const CreateContactNote = lazyWithRetry(() => import("../Pages/PatientChart/ContactNote/CreateContactNote"));
const UpdateContactNote = lazyWithRetry(() => import("../Pages/PatientChart/ContactNote/UpdateContactNote"));
const ViewContactNote = lazyWithRetry(() => import("../Pages/PatientChart/ContactNote/ViewContactNote"));
const ViewIncidentReport = lazyWithRetry(() => import("../Pages/PatientChart/IncidentReport/ViewIncidentReport"));
const Authorization = lazyWithRetry(() => import("../Pages/PatientChart/Authorization/Authorization"));
const CreateAuthorization = lazyWithRetry(() => import("../Pages/PatientChart/Authorization/CreateAuthorization"));
const UpdateAuthorization = lazyWithRetry(() => import("../Pages/PatientChart/Authorization/UpdateAuthorization"));
const ViewAuthorization = lazyWithRetry(() => import("../Pages/PatientChart/Authorization/ViewAuthorization"));
const TherapyNote = lazyWithRetry(() => import("../Pages/TherapyNotes/Therapy/TherapyNote"));
const CreateTherapyNote = lazyWithRetry(() => import("../Pages/TherapyNotes/Therapy/CreateTherapyNote"));
const MilegaLog = lazyWithRetry(() => import("../Pages/TherapyNotes/MilegaLog/MilegaLog"));
const CreateMilegaLog = lazyWithRetry(() => import("../Pages/TherapyNotes/MilegaLog/CreateMilegaLog"));
const UpdateMilegaLog = lazyWithRetry(() => import("../Pages/TherapyNotes/MilegaLog/UpdateMilegaLog"));
const ViewMilega = lazyWithRetry(() => import("../Pages/TherapyNotes/MilegaLog/ViewMilega"));
const Manaul = lazyWithRetry(() => import("../Pages/TherapyNotes/Manual/Manaul"));
const Mars = lazyWithRetry(() => import("../Pages/Medication/Mars/Mars"));
const Reconciliation = lazyWithRetry(() => import("../Pages/Medication/Reconciliation/Reconciliation"));
const UpdateRecociliation = lazyWithRetry(() => import("../Pages/Medication/Reconciliation/UpdateRecociliation"));
const ViewRecociliation = lazyWithRetry(() => import("../Pages/Medication/Reconciliation/ViewRecociliation"));
const ViewPrn = lazyWithRetry(() => import("../Pages/Medication/MedicationLog/ViewPrn"));
const ScheduleGroup = lazyWithRetry(() => import("../Pages/Time Sheet/ScheduleGroup"));
// const Login = lazyWithRetry(() => import("../Pages/Login/Login"));
const ViewProgreeNote = lazyWithRetry(() => import("../Pages/PatientChart/Progress/ViewProgreeNote"));
const CreateMentalStatus = lazyWithRetry(() => import("../Pages/Medication/MentalStatus/CreateMentalStatus"));
const MentalStatus = lazyWithRetry(() => import("../Pages/Medication/MentalStatus/MentalStatus"));
const UpdateMentalStatus = lazyWithRetry(() => import("../Pages/Medication/MentalStatus/UpdateMentalStatus"));
const ViewMentalStatus = lazyWithRetry(() => import("../Pages/Medication/MentalStatus/ViewMentalStatus"));
const Refusal = lazyWithRetry(() => import("../Pages/Medication/Refusal/Refusal"));
const UpdateRefusal = lazyWithRetry(() => import("../Pages/Medication/Refusal/UpdateRefusal"));
const ViewRefusal = lazyWithRetry(() => import("../Pages/Medication/Refusal/ViewRefusal"));
const CreateTrackingLog = lazyWithRetry(() => import("../Pages/Medication/TrackingLog/CreateTrackingLog"));
const TrackingLog = lazyWithRetry(() => import("../Pages/Medication/TrackingLog/TrackingLog"));
const UpdateTracking = lazyWithRetry(() => import("../Pages/Medication/TrackingLog/UpdateTracking"));
const ViewTackingLog = lazyWithRetry(() => import("../Pages/Medication/TrackingLog/ViewTackingLog"));
const FaceSheet = lazyWithRetry(() => import("../Pages/Intake/FaceSheet/FaceSheet"));
const FaceSheetList = lazyWithRetry(() => import("../Pages/Intake/FaceSheet/FaceSheetList"));
const NursingAssisment = lazyWithRetry(() => import("../Pages/Intake/NursingAssisment/NursingAssisment"));
const NursingAssismentList = lazyWithRetry(() => import("../Pages/Intake/NursingAssisment/NursingAssismentList"));
const ResidentIntake = lazyWithRetry(() => import("../Pages/Intake/ResidentIntake/ResidentIntake"));
const ResidentIntakeList = lazyWithRetry(() => import("../Pages/Intake/ResidentIntake/ResidentIntakeList"));
const TreatmentPlan = lazyWithRetry(() => import("../Pages/Intake/TreatmentPlan/TreatmentPlan"));
const TreatmentPlanList = lazyWithRetry(() => import("../Pages/Intake/TreatmentPlan/TreatmentPlanList"));
const SafetyPlan = lazyWithRetry(() => import("../Pages/Intake/SafetyPlan/SafetyPlan"));
const SafetyPlanList = lazyWithRetry(() => import("../Pages/Intake/SafetyPlan/SafetyPlanList"));
const TBRiskAssessmentWrapper = lazyWithRetry(() => import("../Pages/EmploymentInformation/Appendix/TBRiskAssessmentWrapper"));
const Intake = lazyWithRetry(() => import("../Pages/Intake/Intake"));
const InitialAssisment = lazyWithRetry(() => import("../Pages/Intake/InitalAssisment/InitialAssisment"));
const All = lazyWithRetry(() => import("../Pages/EmploymentInformation/All"));
const ViewFaceSheet = lazyWithRetry(() => import("../Pages/Intake/FaceSheet/ViewFaceSheet"));
const Chat = lazyWithRetry(() => import("../Pages/Chat/Chat"));
// const PatientChat = lazyWithRetry(() => import("../Pages/Chat/PatientChat"));
const ViewRefrenceCheck = lazyWithRetry(() => import("../Pages/EmploymentInformation/ReferenceCheck/ViewRefrenceCheck"));
const EditRefrenceCheck = lazyWithRetry(() => import("../Pages/EmploymentInformation/ReferenceCheck/EditRefrenceCheck"));
// const GroupChat = lazyWithRetry(() => import("../Pages/Chat/GroupChat/GroupChat"));
const EditTimeOfRequest = lazyWithRetry(() => import("../Pages/Time Off Request/EditTimeOfRequest"));
const InfectionControl = lazyWithRetry(() => import("../Pages/InfectionControl/InfectionControl"));
const CreateAssistanceMed = lazyWithRetry(() => import("../Pages/AssistanceMed/CreateAssistanceMed"));
const GetInfectionControl = lazyWithRetry(() => import("../Pages/InfectionControl/GetInfectionControl"));
const ViewInfectionControl = lazyWithRetry(() => import("../Pages/InfectionControl/ViewInfectionControl"));
const EditInfectionControl = lazyWithRetry(() => import("../Pages/InfectionControl/EditInfectionControl"));
const AssistanceMed = lazyWithRetry(() => import("../Pages/AssistanceMed/AssistanceMed"));
const ViewAssistanceMed = lazyWithRetry(() => import("../Pages/AssistanceMed/ViewAssistanceMed"));
const EditAssistanceMed = lazyWithRetry(() => import("../Pages/AssistanceMed/EditAssistanceMed"));
const CreateFallPrevention = lazyWithRetry(() => import("../Pages/FallPrevention/CreateFallPrevention"));
const FallPrevention = lazyWithRetry(() => import("../Pages/FallPrevention/FallPrevention"));
const ViewFallPrevention = lazyWithRetry(() => import("../Pages/FallPrevention/ViewFallPrevention"));
const EditFallPrevention = lazyWithRetry(() => import("../Pages/FallPrevention/EditFallPrevention"));
const CreateTubercluosis = lazyWithRetry(() => import("../Pages/Tuberculosis/CreateTubercluosis"));
const Tubercluosis = lazyWithRetry(() => import("../Pages/Tuberculosis/Tubercluosis"));
const ViewTubercluosis = lazyWithRetry(() => import("../Pages/Tuberculosis/ViewTubercluosis"));
const EditTubercluosis = lazyWithRetry(() => import("../Pages/Tuberculosis/EditTubercluosis"));
// const ActivitySchedule = lazyWithRetry(() => import("../Pages/Time Sheet/ActivitySchedule"));
const ActivitySchedule = lazyWithRetry(() => import("../Pages/Time Sheet/ActivityScheduleByUser"));
const ViewTherapy = lazyWithRetry(() => import("../Pages/TherapyNotes/Therapy/ViewTherapy"));
const TherapyLog = lazyWithRetry(() => import("../Pages/TherapyNotes/Therapy/TherapyLog"));
const UpdateTherapyLog = lazyWithRetry(() => import("../Pages/TherapyNotes/Therapy/UpdateTherapyLog"));
const SignAppendix = lazyWithRetry(() => import("../Pages/EmploymentInformation/Appendix/SignAppendix"));
const ViewAppendix = lazyWithRetry(() => import("../Pages/EmploymentInformation/Appendix/ViewAppendix"));
const SignPersonal = lazyWithRetry(() => import("../Pages/EmploymentInformation/Peronal_Information/SignPersonal"));
const EmployeePerformanceById = lazyWithRetry(() => import("../Pages/Employee Performance/EmployeePerformanceById"));
const SignJobDescription = lazyWithRetry(() => import("../Pages/EmploymentInformation/JobDescription/SignJobDescription"));
const EmployeeMedication = lazyWithRetry(() => import('../Pages/Medication/MedicationEmployee/Employee_Medication'))
const EmployeeTimeSheet = lazyWithRetry(() => import('../Pages/Time Sheet/TimeSheetList'))
const ClinicalOversight = lazyWithRetry(() => import("../Pages/ClinicalOversight/ClinicalOversight"))
const Receipts = lazyWithRetry(() => import("../Pages/Receipts/Receipts"))
const SpecialNote = lazyWithRetry(() => import("../Pages/SpecialNotes/SpecialNotes"))
const BaseClinicalOversight = lazyWithRetry(() => import("../Pages/ClinicalOversight/BaseClinicalOversight"))

// Resident Routing

// const Home = lazyWithRetry(() => import("./Pages/Home/Home"));
const Appointments = lazyWithRetry(() => import("../resident/Components/Appointments/Appointments"));
const IntakeResident = lazyWithRetry(() => import("../resident/Components/Intake/Intake"));
const InitialAssessment = lazyWithRetry(() => import("../resident/Components/Forms/Initial-Assessment"));
const ViewInitialAssessment = lazyWithRetry(() => import("../resident/Components/Forms/ViewInitialAssessment"));
const SafetyPlanResident = lazyWithRetry(() => import("../resident/Components/Forms/SafetyPlan"));
const ViewSafetyPlan = lazyWithRetry(() => import("../resident/Components/Forms/ViewSafetyPlan"));
const NursingAssessment = lazyWithRetry(() => import("../resident/Components/Forms/Nursing-Assessment"));
const ViewNursingAssessment = lazyWithRetry(() => import("../resident/Components/Forms/ViewNursingAssessment"));
const ResidentIntakes = lazyWithRetry(() => import("../resident/Components/Forms/ResidentIntakes"));
const ViewResidentIntakes = lazyWithRetry(() => import("../resident/Components/Forms/ViewResidentIntakes"));
const TreatmentplanUpdate = lazyWithRetry(() => import("../resident/Components/Forms/TreatmentPlan_update"));
const ViewTreatmentPlan = lazyWithRetry(() => import("../resident/Components/Forms/ViewTreatmentPlan"));
const FaceSheetResident = lazyWithRetry(() => import("../resident/Components/Forms/FaceSheet"));
const InitialAssessmentDraft = lazyWithRetry(() => import("../resident/Components/Forms/Initial-AssessmentDraft"));
const FaceSheetDraft = lazyWithRetry(() => import("../resident/Components/Forms/FaceSheetDraft"));
const SafetyPlanDraft = lazyWithRetry(() => import("../resident/Components/Forms/SafetyPlanDraft"));
const NursingAssessmentDarft = lazyWithRetry(() => import("../resident/Components/Forms/Nursing-AssessmentDraft"));
const TreatmentplanUpdateDraft = lazyWithRetry(() => import("../resident/Components/Forms/TreatmentPlan_updateDarft"));
const ResidentIntakesDraft = lazyWithRetry(() => import("../resident/Components/Forms/ResidentIntakesDraft"));
const FileUpload = lazyWithRetry(() => import("../resident/Components/Appointments/FileUpload"));
const AppointmentScheduling = lazyWithRetry(() => import("../resident/Components/Appointment Scheduling/AppointmentScheduling"));
const BookAppointmentResident = lazyWithRetry(() => import("../resident/Components/Forms/BookAppointment"));
const ManageAppointments = lazyWithRetry(() => import("../resident/Components/Forms/ManageAppointments"));
const CancelAppointment = lazyWithRetry(() => import("../resident/Components/Forms/CancelAppointment"));
const AppointmentHistory = lazyWithRetry(() => import("../resident/Components/Forms/AppointmentHistory"));
const ChatResident = lazyWithRetry(() => import("../resident/chat/Chat"));
const ProgressChartResident = lazyWithRetry(() => import('../resident/Components/ProgressChart/ProgressChartResident'))
const ViewClinicalOversight = lazyWithRetry(() => import('../Pages/ClinicalOversight/ViewClinicalOversight'))
// const MedicationResident = lazyWithRetry(()=>import('../resident/Components/Medication/MedicationResident'))
// const UpdateProfile = lazyWithRetry(() => import("../resident/Components/Forms/UpdateProfile"));
// const SaveAsDraft = lazyWithRetry(() => import("./Components/Intake/SaveAsDraft"));
export const adminRoutes = [
  // { path: '/adminPage', element: <AdminLogo /> },
  { path: '/dashboard/contacts', element: <Contacts /> },
  { path: '/dashboard/tracking', element: <Tracking /> },
  { path: '/dashboard/logs', element: <Logs /> },
  { path: '/dashboard/activityLog', element: <ActivityLog /> },
  // { path: '/dashboard/pto', element: <PTORequest /> },
  { path: '/dashboard/staff-schedule', element: <StaffSchedule2 /> },
  { path: '/dashboard/activity-schedule', element: <ActivityScheduleAdmin /> },
  { path: '/dashboard/employee-performance', element: <EmployeePerformanceAdmin /> },
  // { path: '/dashboard/notes', element: <NotesLibrary2 /> },
  { path: '/dashboard/notifications', element: <Notifications /> },
  { path: '/dashboard/calender-schedule', element: <CalenderSchedule /> },
  { path: '/dashboard/job-description', element: <JobDescriptionAdmin /> },
  { path: '/dashboard/offer-letter', element: <OfferLater /> },
  { path: '/dashboard/patient-vitals', element: <PatientVitals /> },
  { path: '/dashboard/patient-tracking', element: <PatinentTracking /> },
  { path: '/dashboard/homepage', element: <HomePage1 /> },
  { path: '/dashboard/reference-check', element: <ReferenceCheckAdmin /> },
  { path: '/employment-admin', element: <Employment /> },
  { path: '/employee-list', element: <EmployeeList /> },
  { path: '/dashboard/employee-data/:id', element: <EmployeeData /> },
  { path: '/dashboard/personal-information/:employeeId', element: <Personal /> },
  { path: '/dashboard/employee-tracking/:employeeId', element: <EmployeeTracking /> },
  { path: '/dashboard/sign-offer-letter-form/:id', element: <OfferLetter /> },
  { path: '/dashboard/sign-personal/:employeeId', element: <SignPersonal /> },
  { path: '/dashboard/employee-information/:employeeId', element: <PersonalInfo /> },
  { path: '/dashboard/view-employee-information/:employeeId', element: <ViewInfo /> },
  { path: '/dashboard/view-appendix/:id', element: <TBRiskAssessmentWrapper component={ViewAppendix} /> },
  { path: '/dashboard/edit-appendix/:id', element: <TBRiskAssessmentWrapper component={SignAppendix} /> },
  // { path: '/dashboard/create-forms-2023', element: <Create2023 /> },
  // { path: '/dashboard/forms-2023/:employeeId', element: <Forms2023 /> }, //
  { path: '/dashboard/all-forms-2023', element: <All2023 /> },
  // { path: '/dashboard/lrc-1031a/:employeeId', element: <LRC1031 /> }, //
  { path: '/dashboard/all-lrc-1031a/', element: <AllLRC /> },
  // { path: '/dashboard/create-lrc-1031a', element: <CreateLrc /> },
  { path: '/dashboard/sign-job-description/:employeeId', element: <SignJobDescription /> },
  // { path: '/dashboard/fw4/:employeeId', element: <FW4Form /> }, //
  // { path: '/dashboard/create-fw4', element: <CreateFw4 /> },
  { path: '/dashboard/all-fw4', element: <AllFW4 /> },
  { path: '/dashboard/edit-aps/:employeeId', element: <EditAPS /> },
  { path: '/dashboard/all-fw9', element: <AllFW9 /> },
  // { path: '/dashboard/create-fw9', element: <CreateFw9 /> },
  { path: '/dashboard/all-i9', element: <AllI9 /> },
  // { path: '/dashboard/create-i9', element: <CreateI9 /> },
  { path: '/dashboard/view-aps/:employeeId', element: <ViewAps /> },
  // { path: '/dashboard/fw9/:employeeId', element: <FW9 /> }, //
  // { path: '/dashboard/i-9/:employeeId', element: <I9Form /> }, //
  { path: '/dashboard/all-forms/:employeeId', element: <All /> },
  { path: '/dashboard/on-site/:employeeId', element: <OnSite /> },
  { path: '/dashboard/get-infection-control/:employeeId', element: <GetInfectionControl /> },
  { path: '/dashboard/job-description/:employeeId', element: <JobDescription /> },
  { path: '/dashboard/view-site/:employeeId', element: <ViewSite /> },
  { path: '/dashboard/edit-site/:employeeId', element: <EditOnSite /> },
  { path: '/dashboard/infection-control/:employeeId', element: <ViewInfectionControl /> },
  { path: '/dashboard/edit-infection-control/:employeeId', element: <EditInfectionControl /> },
  { path: '/dashboard/view-assistance-med/:employeeId', element: <ViewAssistanceMed /> },
  { path: '/dashboard/edit-assistance-med/:employeeId', element: <EditAssistanceMed /> },
  { path: '/dashboard/fall-prevention/:employeeId', element: <ViewFallPrevention /> },
  { path: '/dashboard/edit-fall-prevention/:employeeId', element: <EditFallPrevention /> },
  { path: '/dashboard/tubercluosis/:employeeId', element: <ViewTubercluosis /> },
  { path: '/dashboard/edit-tubercluosis/:employeeId', element: <EditTubercluosis /> },
  { path: '/dashboard/time-sheet/:employeeId', element: <TimeSheetView /> },
  { path: '/dashboard/view-time-of-request/:employeeId', element: <ViewTimeOfRequest /> },
  { path: '/dashboard/edit-time-off-request/:employeeId', element: <EditTimeOfRequest /> },
  { path: '/dashboard/employee-performance/:id', element: <EmployeePerformance /> },
  { path: '/dashboard/update-employee-performance/:id', element: <EmployeePerformanceEditAdmin /> },
  { path: '/dashboard/view-refrence-check/:employeeId', element: <ViewRefrenceCheck /> },
  { path: '/dashboard/edit-refrence-check/:id', element: <EditRefrenceCheck /> },
  { path: '/dashboard/view-service-log/:employeeId', element: <ViewServiceLog /> },
  { path: '/dashboard/edit-service-log/:employeeId', element: <EditServiceLog /> },
  { path: '/dashboard/view-site-training/:employeeId', element: <ViewSkills /> },
  { path: '/dashboard/edit-skill-training/:employeeId', element: <EditSkill /> },
  { path: '/dashboard/all-appendix', element: <TBRiskAssessmentWrapper component={AllAppendix} /> },
  { path: '/offer-letter/:employeId', element: <OfferletterAdmin /> },
  // { path: '/dashboard/employeeTermination/:employeeId', element: <EditSkill /> },
  // { path: '/dashboard/employeeTermination/:employeeId', element: <EditSkill /> },
  { path: '/dashboard/employeeTermination', element: <EmployeeTermination /> },
  { path: '/dashboard/updateEmployeeTermination/:id', element: <UpdateEmployeeTermination /> },
  { path: '/dashboard/viewEmployeeTermination/:id', element: <ViewEmployeeTermination /> },
  { path: '/dashboard/EmployeeTerminationlist', element: <EmployeeTerminationlist /> },
  { path: '/edit-vital/:id', element: <EditVitals /> },
  { path: '/view-employement-application/:employeId', element: <ViewEmploymentApplicationForm /> },
  { path: '/basic-information/:employeId', element: <BasicInformation /> },
  { path: '/educational-background/:employeId', element: <EducationalBackground /> },
  { path: '/employement-history/:employeId', element: <EmploymentHistory /> },
  { path: '/other-information/:employeId', element: <OtherInformation /> },
  { path: '/acknowledgement/:employeId', element: <Acknowledgement /> },


  { path: '/dashboard/forms-2023/:id', element: <Forms2023 /> },
  { path: '/dashboard/lrc-1031a/:id', element: <LRC1031 /> },
  { path: '/dashboard/fw4/:id', element: <FW4Form /> },
  { path: '/dashboard/i-9/:id', element: <I9Form /> },
  { path: '/dashboard/fw9/:id', element: <FW9 /> },
  { path: '/create-on-site-facility', element: <OnSite2 /> },
  { path: '/create-skill-training', element: <Skills2 /> },
];

export const employeeRoutes = [
  { path: '/home', element: <Dashboard /> },
  { path: '/create-refusal', element: <CreateRefusal /> },
  { path: '/employment', element: <Employment /> },
  { path: '/offer-letter-form/:id', element: <OfferLetter /> },
  { path: '/offer-letter-form', element: <OfferLetter /> },
  // { path: '/profile', element: <Profile /> },
  { path: '/sign-offer-letter-form/:id', element: <SignOfferLetter /> },
  { path: '/appendix', element: <Appendix /> },
  { path: '/appendix-list', element: <TBRiskAssessmentWrapper component={AllAppendix} /> },
  { path: '/sign-appendix/:id', element: <TBRiskAssessmentWrapper component={SignAppendix} /> },
  { path: '/view-appendix/:id', element: <TBRiskAssessmentWrapper component={ViewAppendix} /> },
  { path: '/forms-2023', element: <Forms2023 /> },
  { path: '/lrc-1031a', element: <LRC1031 /> },
  { path: '/aps-consent-form', element: <APS /> },
  { path: '/job-description', element: <JobDescription /> },
  { path: '/job-description/:id', element: <JobDescription /> },
  { path: '/sign-job-description/:id', element: <SignJobDescription /> },
  { path: '/fw4', element: <FW4Form /> },
  { path: '/i-9', element: <I9Form /> },
  { path: '/fw9', element: <FW9 /> },
  { path: '/progree-note/:id', element: <EditProgressNote /> },
  { path: '/update-count/:id', element: <UpdateCount /> },
  { path: '/view-count/:id', element: <ViewCount /> },
  { path: '/view-inform/:id', element: <ViewInform /> },
  { path: '/personal-information', element: <Personal /> },
  { path: '/create-personal-information', element: <Personal /> },
  { path: '/sign-personal/:id', element: <SignPersonal /> },
  { path: '/employee/patient-chart/progress', element: <ProgressNote /> },
  { path: '/view-progress-note/:id', element: <ViewProgreeNote /> },
  { path: '/create-progress-note', element: <ProgressNote2 /> },
  { path: '/employee-information', element: <PersonalInfo /> },
  { path: '/view-employee-information', element: <ViewInfo /> },
  { path: '/view-employee-information/:id', element: <ViewInfo /> },
  { path: '/all-aps', element: <AllAps /> },
  { path: '/edit-aps/:id', element: <EditAPS /> },
  { path: '/view-aps/:id', element: <ViewAps /> },
  { path: '/search-list/:id', element: <SearchPageWrapper /> },
  { path: '/basic-information', element: <BasicInformation /> },
  { path: '/educational-background', element: <EducationalBackground /> },
  { path: '/employement-history', element: <EmploymentHistory /> },
  { path: '/view-employement-application', element: <ViewEmploymentApplicationForm /> },
  { path: '/other-information', element: <OtherInformation /> },
  { path: '/acknowledgement', element: <Acknowledgement /> },
  { path: '/group-notes', element: <GroupNotes /> },
  { path: '/employee/training', element: <Traning /> },
  { path: '/employee/patient-chart', element: <PatientChart /> },
  { path: '/vitals', element: <Vitals /> },
  { path: '/create-vital', element: <Vitals2 /> },
  { path: '/edit-vital/:id', element: <EditVitals /> },
  { path: '/patient-tracking', element: <PatientTracking /> },
  { path: '/medications', element: <Medications /> },
  { path: '/on-site', element: <OnSite /> },
  // { path: '/create-on-site-facility', element: <OnSite2 /> },
  { path: '/edit-on-site/:id', element: <EditOnSite /> },
  { path: '/view-site/:id', element: <ViewSite /> },
  { path: '/service-log', element: <ServiceLog /> },
  { path: '/create-service-log', element: <CreateServiceLog /> },
  { path: '/edit-service-log/:id', element: <EditServiceLog /> },
  { path: '/view-service-log/:id', element: <ViewServiceLog /> },
  { path: '/patient-list', element: <PatientList /> },
  { path: '/book-appointment/:id', element: <BookAppointment /> },
  { path: '/skills-knowledge-training', element: <Skills /> },
  // { path: '/create-skill-training', element: <Skills2 /> },
  { path: '/edit-skill-training/:id', element: <EditSkill /> },
  { path: '/view-site-training/:id', element: <ViewSkills /> },
  { path: '/create-time-of-request', element: <TimeOfRequest /> },
  { path: '/get-time-of-request', element: <GetTimeOfRequest /> },
  { path: '/view-time-of-request/:id', element: <ViewTimeOfRequest /> },
  { path: '/edit-time-off-request/:id', element: <EditTimeOfRequest /> },
  { path: '/schedule', element: <Schedule /> },
  { path: '/time-sheet', element: <TimeSheet /> },
  { path: '/time-sheet/:id', element: <TimeSheetView /> },
  { path: '/timesheet-list', element: <EmployeeTimeSheet /> },
  { path: '/employee-performance', element: <EmployeePerformance /> },
  { path: '/employee-performance/:id', element: <EmployeePerformance /> },
  { path: '/updated-employee-performance/:id', element: <EmployeePerformanceById /> },
  { path: '/create-discharge-summary', element: <CreateDischarge /> },
  { path: '/discharge-summary', element: <Discharge /> },
  { path: '/update-discharge/:id', element: <UpdateDischarge /> },
  { path: '/view-discharge/:id', element: <ViewDischarge /> },
  { path: '/DTF', element: <DTF /> },
  { path: '/create-dtf', element: <CreateDTF /> },
  { path: '/update-dtf/:id', element: <UpdateDTF /> },
  { path: '/view-dtf/:id', element: <ViewDTF /> },
  { path: '/financial-record', element: <FinancialRecord /> },
  { path: '/create-record', element: <CreateRecord /> },
  { path: '/edit-record/:id', element: <EditFinancialRecord /> },
  { path: '/view-record/:id', element: <ViewFinancialRecord /> },
  { path: '/staff-note', element: <StaffNotes /> },
  { path: '/create-staff-note', element: <CreateStaffNote /> },
  { path: '/update-staff-note/:id', element: <UpdateStaffNote /> },
  { path: '/view-staff-note/:id', element: <ViewStaffNote /> },
  { path: '/incident-report', element: <IncidentReport /> },
  { path: '/update-incident/:id', element: <UpdateIncidentReport /> },
  { path: '/create-incident-report', element: <CreateIncident /> },
  { path: '/view-incident-report/:id', element: <ViewIncidentReport /> },
  { path: '/contact-note', element: <ContactNote /> },
  { path: '/create-contact-note', element: <CreateContactNote /> },
  { path: '/update-contact-note/:id', element: <UpdateContactNote /> },
  { path: '/view-contact-note/:id', element: <ViewContactNote /> },
  { path: '/authorization', element: <Authorization /> },
  { path: '/create-authorization', element: <CreateAuthorization /> },
  { path: '/update-authorization/:id', element: <UpdateAuthorization /> },
  { path: '/view-authorization/:id', element: <ViewAuthorization /> },
  { path: '/therapy-notes', element: <TherapyNote /> },
  { path: '/therapy-log', element: <TherapyLog /> },
  { path: '/update-therapy-log/:id', element: <UpdateTherapyLog /> },
  { path: '/view-therapy-log/:id', element: <ViewTherapy /> },
  { path: '/create-therapy-note', element: <CreateTherapyNote /> },
  { path: '/milega-log', element: <MilegaLog /> },
  { path: '/create-milega-log', element: <CreateMilegaLog /> },
  { path: '/update-milega-log/:id', element: <UpdateMilegaLog /> },
  { path: '/view-milega-log/:id', element: <ViewMilega /> },
  { path: '/manual-theraphy', element: <Manaul /> },
  { path: '/mars', element: <Mars /> },
  { path: '/mars/:id', element: <Mars /> },
  { path: '/reconciliation', element: <Reconciliation /> },
  { path: '/create-reconciliation', element: <Reconciliations2 /> },
  { path: '/update-reconciliation/:id', element: <UpdateRecociliation /> },
  { path: '/view-reconciliation/:id', element: <ViewRecociliation /> },
  { path: '/employee/medications/medication-count', element: <MedicationCount /> },
  { path: '/create-medication-count', element: <MedicautionCount2 /> },
  { path: '/employee/medications/informed-consent', element: <InformedConsent /> },
  { path: '/create-informed-consent', element: <InformedConsent2 /> },
  { path: '/update-informed/:id', element: <UpdateInform /> },
  { path: '/employee/medications/prn-form', element: <PRNform /> },
  { path: '/create-prn-log', element: <PRNform2 /> },
  { path: '/view-prn/:id', element: <ViewPrn /> },
  { path: '/update-prn/:id', element: <UpdatePrn /> },
  { path: '/scheduleGroup', element: <ScheduleGroup /> },
  { path: '/create-mental-status', element: <CreateMentalStatus /> },
  { path: '/mental-status', element: <MentalStatus /> },
  { path: '/update-mental-status/:id', element: <UpdateMentalStatus /> },
  { path: '/view-mental-status/:id', element: <ViewMentalStatus /> },
  { path: '/create-refusal', element: <CreateRefusal /> },
  { path: '/refusal', element: <Refusal /> },
  { path: '/update-refusal/:id', element: <UpdateRefusal /> },
  { path: '/view-refusal/:id', element: <ViewRefusal /> },
  { path: '/create-tracking-log', element: <CreateTrackingLog /> },
  { path: '/tracking-log', element: <TrackingLog /> },
  { path: '/update-tracking-log/:id', element: <UpdateTracking /> },
  { path: '/view-tracking-log/:id', element: <ViewTackingLog /> },
  { path: '/edit-faceSheet/:id', element: <FaceSheet /> },
  { path: '/faceSheet', element: <FaceSheet /> },
  { path: '/faceSheet-list', element: <FaceSheetList /> },
  { path: '/view-faceSheet/:id', element: <ViewFaceSheet /> },
  { path: '/view-nursing-assessment/:id', element: <ViewNursingAssessment /> },
  { path: '/nursing-assessment', element: <NursingAssisment /> },
  { path: '/nursing-assessment-list', element: <NursingAssismentList /> },
  { path: '/edit-nursing-assessment/:id', element: <NursingAssisment /> },
  { path: '/view-resident-intake/:id', element: <ViewResidentIntakes /> },
  { path: '/resident-intake', element: <ResidentIntake /> },
  { path: '/resident-intake-list', element: <ResidentIntakeList /> },
  { path: '/edit-resident-intake/:id', element: <ResidentIntake /> },
  { path: '/view-treatment-plan/:id', element: <ViewTreatmentPlan /> },
  { path: '/treatment-plan', element: <TreatmentPlan /> },
  { path: '/treatment-plan-list', element: <TreatmentPlanList /> },
  { path: '/edit-treatment-plan/:id', element: <TreatmentPlan /> },
  { path: '/view-safety-plan/:id', element: <ViewSafetyPlan /> },
  { path: '/safety-plan', element: <SafetyPlan /> },
  { path: '/safety-plan-list', element: <SafetyPlanList /> },
  { path: '/edit-safety-plan/:id', element: <SafetyPlan /> },
  { path: '/intake/:id', element: <Intake /> },
  { path: '/view-initial-assessment/:id', element: <ViewInitialAssessment /> },
  { path: '/initial-assessment-list', element: <InitialAssismentList /> },
  { path: '/initial-assessment', element: <InitialAssisment /> },
  { path: '/initial-assessment/:id', element: <InitialAssisment /> },
  { path: '/edit-initial-assessment/:id', element: <InitialAssisment /> },
  { path: '/all-forms', element: <All /> },
  { path: '/chat', element: <Chat /> },
  { path: '/view-refrence-check/:id', element: <ViewRefrenceCheck /> },
  // { path: '/edit-refrence-check/:id', element: <EditRefrenceCheck /> },
  { path: '/create-infection-control', element: <InfectionControl /> },
  { path: '/get-infection-control', element: <GetInfectionControl /> },
  { path: '/infection-control/:id', element: <ViewInfectionControl /> },
  { path: '/edit-infection-control/:id', element: <EditInfectionControl /> },
  { path: '/create-administration-medication', element: <CreateAssistanceMed /> },
  { path: '/get-administration-medication', element: <AssistanceMed /> },
  { path: '/view-assistance-med/:id', element: <ViewAssistanceMed /> },
  { path: '/edit-assistance-med/:id', element: <EditAssistanceMed /> },
  { path: '/create-fall-prevention', element: <CreateFallPrevention /> },
  { path: '/get-fall-prevention', element: <FallPrevention /> },
  { path: '/fall-prevention/:id', element: <ViewFallPrevention /> },
  { path: '/edit-fall-prevention/:id', element: <EditFallPrevention /> },
  { path: '/create-tubercluosis', element: <CreateTubercluosis /> },
  { path: '/tubercluosis', element: <Tubercluosis /> },
  { path: '/tubercluosis/:id', element: <ViewTubercluosis /> },
  { path: '/edit-tubercluosis/:id', element: <EditTubercluosis /> },
  { path: '/activity-schedule', element: <ActivitySchedule /> },
  { path: '/employee-medication', element: <EmployeeMedication /> },
  { path: '/clinical-oversight', element: <ClinicalOversight /> },
  { path: '/clinical-oversight/:id', element: <ClinicalOversight /> },
  { path: '/receipts', element: <Receipts /> },
  { path: '/special-notes', element: <SpecialNote /> },
  { path: '/edit-refrence-check/:id', element: <SignReferenceCheck /> },
  { path: '/list-clinical-oversight', element: <BaseClinicalOversight /> },
  { path: '/view-clinical-oversight/:id', element: <ViewClinicalOversight /> },
  { path: '/updateEmployeeTermination/:id', element: <UpdateEmployeeTermination /> },
  { path: '/viewEmployeeTermination/:id', element: <ViewEmployeeTermination /> },
  { path: '/viewEmployeeTermination', element: <ViewEmployeeTermination /> },
  { path: '/multiEmployeeTrackingList', element: <MultiEmployeeTrackingList /> },
  { path: '/upload-employee-tracking', element: <CreateMultiEmployeeTracking /> },
  { path: '/upload-employee-tracking/:id', element: <CreateMultiEmployeeTracking /> },

  { path: '/employee-tracking', element: <MultiEmployeeTrackingList /> },

  { path: '/all-fw4', element: <AllFW4 /> },
  { path: '/all-forms-2023', element: <All2023 /> },
  { path: '/all-lrc-1031a/', element: <AllLRC /> },
  { path: '/all-fw9', element: <AllFW9 /> },
  { path: '/all-i9', element: <AllI9 /> },
  { path: '/employee-edit-vital/:id', element: <EditVitals /> },

  { path: '/forms-2023/:id', element: <Forms2023 /> },
  { path: '/lrc-1031a/:id', element: <LRC1031 /> },
  { path: '/fw4/:id', element: <FW4Form /> },
  { path: '/i-9/:id', element: <I9Form /> },
  { path: '/fw9/:id', element: <FW9 /> },


];
export const residentRoutes = [
  // { path: '/home', element: <Home /> },
  { path: '/patient_panel', element: <Appointments /> },
  { path: '/patient_Upload_script', element: <FileUpload /> },
  { path: '/intake', element: <IntakeResident /> },
  { path: '/appointment_scheduling', element: <AppointmentScheduling /> },

  { path: '/edit-initial-Assessment-resident/:id', element: <InitialAssessment /> },
  { path: '/initial-Assessment-resident-list', element: <InitialAssismentList /> },
  { path: '/view-initial-assessment-resident/:id', element: <ViewInitialAssessment /> },

  { path: '/edit-facesheet-resident/:id', element: <FaceSheetResident /> },
  { path: '/faceSheet-resident-list', element: <FaceSheetList /> },
  { path: '/view-faceSheet-resident/:id', element: <ViewFaceSheet /> },

  { path: '/edit-safetyplan-resident/:id', element: <SafetyPlanResident /> },
  { path: '/safety-plan-resident-list', element: <SafetyPlanList /> },
  { path: '/view-safety-plan-resident/:id', element: <ViewSafetyPlan /> },

  { path: '/edit-nursing-assessment-resident/:id', element: <NursingAssessment /> },
  { path: '/nursing-assessment-resident-list', element: <NursingAssismentList /> },
  { path: '/view-nursing-assessment-resident/:id', element: <ViewNursingAssessment /> },

  { path: '/edit-treatmentplan-resident/:id', element: <TreatmentplanUpdate /> },
  { path: '/treatment-plan-resident-list', element: <TreatmentPlanList /> },
  { path: '/view-treatment-plan-resident/:id', element: <ViewTreatmentPlan /> },


  { path: '/edit-residentintakes-resident/:id', element: <ResidentIntakes /> },
  { path: '/resident-intake-resident-list', element: <ResidentIntakeList /> },
  { path: '/view-resident-intake-resident/:id', element: <ViewResidentIntakes /> },


  { path: '/initial-Assessment-draft', element: <InitialAssessmentDraft /> },
  { path: '/facesheet-draft', element: <FaceSheetDraft /> },
  { path: '/safetyplan-draft', element: <SafetyPlanDraft /> },
  { path: '/nursing-assessment-draft', element: <NursingAssessmentDarft /> },
  { path: '/treatmentplan-draft', element: <TreatmentplanUpdateDraft /> },
  { path: '/Residentintakes-draft', element: <ResidentIntakesDraft /> },
  // { path: '/chatPatient', element: <ChatResident /> },
  { path: '/chatPatient', element: <Chat /> },
  { path: '/booknewappointment', element: <BookAppointmentResident /> },
  { path: '/appointmenthistory', element: <AppointmentHistory /> },
  // { path: '/update-profile', element: <UpdateProfile /> },
  { path: '/manageappointment', element: <ManageAppointments /> },
  { path: '/cancel_appointment', element: <CancelAppointment /> },
  { path: '/progress-chart-resident', element: <ProgressChartResident /> },
  // { path: '/medication-resident', element: <MedicationResident /> },

  { path: '/discharge-summary-resident-list', element: <Discharge /> },
  { path: '/edit-discharge-summary-resident/:id', element: <UpdateDischarge /> },
  { path: '/view-discharge-summary-resident/:id', element: <ViewDischarge /> },

  { path: '/staff-note-resident-list', element: <StaffNotes /> },
  { path: '/edit-staff-note-resident/:id', element: <UpdateStaffNote /> },
  { path: '/view-staff-note-resident/:id', element: <ViewStaffNote /> },

  { path: '/authorization-resident-list', element: <Authorization /> },
  { path: '/edit-authorization-resident/:id', element: <UpdateAuthorization /> },
  { path: '/view-authorization-resident/:id', element: <ViewAuthorization /> },

  { path: '/mars-resident', element: <Mars /> },

  { path: '/informed-consent-resident-list', element: <InformedConsent /> },
  { path: '/edit-informed-consent-resident/:id', element: <UpdateInform /> },
  { path: '/view-informed-consent-resident/:id', element: <ViewInform /> },

  { path: '/prn-log-resident-list', element: <PRNform /> },
  { path: '/edit-prn-log-resident/:id', element: <UpdatePrn /> },
  { path: '/view-prn-log-resident/:id', element: <ViewPrn /> },

  { path: '/refusal-resident-list', element: <Refusal /> },
  { path: '/edit-refusal-resident/:id', element: <UpdateRefusal /> },
  { path: '/view-refusal-resident/:id', element: <ViewRefusal /> },

  { path: '/appendix-resident-list', element: <TBRiskAssessmentWrapper component={AllAppendix} /> },
  { path: '/sign-appendix-resident/:id', element: <TBRiskAssessmentWrapper component={SignAppendix} /> },
  { path: '/view-appendix-resident/:id', element: <TBRiskAssessmentWrapper component={ViewAppendix} /> },

  { path: '/bookappointment-resident', element: <CreateTrackingLog /> },
  { path: '/assign-resident-list', element: <PatientList /> },
  { path: '/bookNewAppointment/:id', element: <BookAppointment /> },


]