/** @format */

import React, { useEffect, useState } from "react";
import HOC from "../../Layout/Outer/HOC";
import {
  getApi,
  getObjectUrlFromDownloadUrl,
  showNotification,
  UpdateProfile,
} from "../../Repository/Apis";
import { Container, Row, Col, Form, Card, Modal, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/authSlice";
import { defaultProfileIcon, defaultLogo } from "../../assets/index";
import UpdatePassword from "./UpdatePassword";
import StaffFacility from "./StaffFacility";
import ChangePlan from "./ChangePlan";
import CanclePlan from "./CanclePlan";
import { formatDateToMMDDYYYY } from "../../utils/utils";
import { MdApartment, MdOutlinePermIdentity, MdCardMembership } from "react-icons/md";


const InputGroup = ({
  label,
  setValue,
  value,
  placeholder,
  type = "text",
  pattern,
  required = false,
}) => {
  return (
    <div className="Input_Group mb-3">
      <Form.Label className="fw-bold">
        {label}
        {required && <span style={{ color: 'red' }}> * </span>}
      </Form.Label>
      <Form.Control
        required={required}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        pattern={type === "tel" ? pattern : undefined}
      ></Form.Control>
    </div>
  );
};


const AmountCard = ({ data }) => {
  const amounts = [
    { label: "Amount Due", value: data?.totalDue || "0.00" },
    { label: "Amount Paid", value: data?.totalPaid || "0.00" },
    { label: "Amount Remaining", value: data?.totalPending || "0.00" },
  ];

  return (
    <Row className="text-center g-2">
      {amounts.map((amount, index) => (
        <Col xs={4} key={index}>
          <Card className="border-dashed-invoice shadow-sm">
            <Card.Body className="p-1">
              <p className="fw-bold mb-1">{`$ ${amount.value}`}</p>
              <p className="text-muted small mb-0">{amount.label}</p>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const Profile = () => {
  const ProfileDetails = useSelector(userProfile);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middle, setMiddle] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [format, setFormat] = useState(false);
  const [showFacility, setShowFacility] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [showCanclePlan, setShowCanclePlan] = useState(false);
  const [planData, setPlanData] = useState({});
  const [isLoadingSubcription, setIsLoadingSubcription] = useState(false);
  const dispatch = useDispatch();

  const fetchHandler = () => {
    getApi({
      url: `admin/get-subscription-details`,
      setResponse: setPlanData,
      setIsLoadingSubcription
    });
  };

  useEffect(() => {
    fetchHandler();
  }, [showCanclePlan, showPlan]);

  useEffect(() => {
    if (ProfileDetails) {
      setEmail(ProfileDetails?.email);
      setMobileNumber(ProfileDetails?.mobileNumber);
      setAddress(ProfileDetails?.address);
      setFirstName(ProfileDetails?.firstName);
      setLastName(ProfileDetails?.lastName);
      setMiddle(ProfileDetails?.middle || "");
      setGender(ProfileDetails?.gender);
      setCompanyName(ProfileDetails?.companyName);
      setLogoImage(ProfileDetails?.logo);
      setFormat(ProfileDetails?.hoursFormat);
    }
  }, [ProfileDetails]);





  const fd = new FormData();

  function appendIfPresent(formValue, value, canEmpty = false) {
    if (canEmpty || value) {
      fd.append(formValue, value);
    }
  }
  appendIfPresent("image", image);
  appendIfPresent("firstName", firstName);
  appendIfPresent("lastName", lastName);
  appendIfPresent("middle", middle, true);
  appendIfPresent("gender", gender);
  appendIfPresent("mobileNumber", mobileNumber, true);
  appendIfPresent("email", email);
  appendIfPresent("address", address, true);
  appendIfPresent("logo", logoImage);
  appendIfPresent("companyName", companyName);
  if (ProfileDetails?.userType === "Admin") {
    appendIfPresent("hoursFormat", format);
  }

  const handleSubmit = async (e) => {
    let updateUrl;
    if (ProfileDetails?.userType === "Admin") {
      updateUrl = `/admin/updateProfile`;
    } else if (ProfileDetails?.userType === "Employee") {
      updateUrl = `employee/updateProfile`;
    } else if (ProfileDetails.userType === "Patient") {
      updateUrl = `Patient/updateProfile`;
    }
    e.preventDefault();
    dispatch(
      UpdateProfile({
        payload: fd,
        setLoading,
        url: updateUrl,
        isAdmin: ProfileDetails?.userType === "Admin",
      })
    );
  };
  
  const selectImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({
          message: "Please select a valid JPG or PNG image.",
          type: "default",
        });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({
          message: "File size should be less than 2 MB.",
          type: "default",
        });
        return;
      }
      setImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
    }
  };
  const selectImageLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      if (!validFileTypes.includes(file.type)) {
        showNotification({
          message: "Please select a valid JPG or PNG image.",
          type: "default",
        });
        return;
      }
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        showNotification({
          message: "File size should be less than 2 MB.",
          type: "default",
        });
        return;
      }
      setLogoImage(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImageUrl(imageUrl);
    }
  };

  const ChooseFile = () => {
    const target = document.getElementById("file");
    target.click();
  };
  const ChooseFileLogo = () => {
    const target = document.getElementById("logoFile");
    target.click();
  };



  return (
    <>
      <UpdatePassword show={modalShow} onHide={() => setModalShow(false)} />
      <StaffFacility
        show={showFacility}
        onHide={() => setShowFacility(false)}
      />
      <ChangePlan
        show={showPlan}
        onHide={() => setShowPlan(false)}
        plan={planData?.data?.tier}
      />
      <CanclePlan
        show={showCanclePlan}
        onHide={() => setShowCanclePlan(false)}
        planCheck={planData?.data && planData?.data?.subscription?.cancel_at_period_end}
      />

      <Container>
        {/* <div className="page-title-bar mb-3">
          <Row>
            <Col xs={12} md={4} xl={3}></Col>
            <Col xs={12} md={4} xl={6}>
              <p className="heading">Profile</p>
            </Col>
            <Col xs={12} md={4} xl={3}>
              {ProfileDetails.userType === "Admin" && (
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setShowFacility(true)}
                    className="upload-btn "
                  >
                    {" "}
                    Add Facility{" "}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div> */}
        <div className="page-name-bar mb-2">
          <Row className="align-items-center">
            <Col xs={8} sm={7} md={8} xl={8}>
              <p className="page-name fw-bold mb-0">Company & User Profile</p>
            </Col>
            <Col xs={4} sm={5} md={4} xl={4}>
              {ProfileDetails.userType === "Admin" && (
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setShowFacility(true)}
                    className="blue-theme-btn"
                  >
                    {" "}
                    Add Facility{" "}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="main-page-content">
          <Row>
            <Col xs={12} lg={4} xl={3}>
              <div className="profile-section">
                <div className="main">
                  <div className="contain">
                    <Card body className="mb-3 border-0 relative shadow-sm">
                      <div className="header-card-title mb-3">
                        <div className="d-flex align-items-center">
                          <MdApartment className="me-2 icon-color" />
                          <h6 className="mb-0">Company Info</h6>
                        </div>
                      </div>
                      <div className="border px-2 py-2 rounded-2">
                          <img
                            src={
                              tempImageUrl
                                ? tempImageUrl
                                : (ProfileDetails?.adminId?.logo || ProfileDetails?.logo  ?  getObjectUrlFromDownloadUrl(
                                  ProfileDetails?.adminId?.logo || ProfileDetails?.logo
                                ) : defaultLogo)
                            }
                            alt=""
                            style={{
                              width: "auto",
                              objectFit: "contain",
                              borderRadius: "0",
                              background: "white",
                              maxHeight:"62px"
                            }}
                          />
                        
                      </div>
                      <h6 className="mt-3 mb-0 text-center fw-bold">
                        {companyName}
                      </h6>
                      <div className="upload-profile">
                        {ProfileDetails.userType === "Admin" && (
                          <button
                            onClick={() => ChooseFileLogo()}
                            className="blue-theme-btn mb-2"
                          >
                            {" "}
                            Change Logo{" "}
                          </button>
                        )}
                        {ProfileDetails.userType === "Admin" && (
                          <div className="Input_Group">
                            <input
                              type={"file"}
                              className="d-none"
                              id="logoFile"
                              accept=".jpg,.png"
                              onChange={(e) => selectImageLogo(e)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => setModalShow(true)}
                          className="blue-theme-btn"
                        >
                          {" "}
                          Change Password{" "}
                        </button>
                      </div>
                    </Card>
                    <Card body className="mb-3 border-0 shadow-sm user-profile-card">
                      <div className="header-card-title mb-3">
                        <div className="d-flex align-items-center">
                          <MdOutlinePermIdentity className="me-2 icon-color" />
                          <h6 className="mb-0">User Profile</h6>
                        </div>
                      </div>
                      <img
                        src={
                          imageUrl
                            ? imageUrl
                            : ProfileDetails?.profilePic
                              ? getObjectUrlFromDownloadUrl(
                                ProfileDetails?.profilePic
                              )
                              : defaultProfileIcon
                        }
                        alt=""
                        className="cursor-pointer user-profile-image"
                      />
                      <h6 className="mt-3 mb-0 text-center fw-bold">
                        {" "}
                        {ProfileDetails?.firstName ?? ""}{" "}
                        {ProfileDetails?.middleName ?? ""}{" "}
                        {ProfileDetails?.lastName ?? ""}{" "}
                      </h6>
                      <div className="upload-profile">
                        <button
                          onClick={() => ChooseFile()}
                          className="blue-theme-btn"
                        >
                          {" "}
                          Change Profile{" "}
                        </button>
                        <div className="Input_Group">
                          <input
                            type={"file"}
                            className="d-none"
                            id="file"
                            onChange={(e) => selectImage(e)}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={8} xl={9}>
              <Card body className="mb-3 border-0 shadow-sm">
                <div className="header-card-title mb-3">
                  <div className="d-flex align-items-center">
                    <MdOutlinePermIdentity className="me-2 icon-color" />
                    <h6 className="mb-0">User Info</h6>
                  </div>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} lg={4}>
                      <InputGroup
                        label={"First Name"}
                        setValue={setFirstName}
                        value={firstName}
                        type="text"
                        placeholder="First Name"
                        required={true}
                      />
                    </Col>
                    <Col xs={12} lg={4}>
                      <InputGroup
                        label={"Middle Name"}
                        setValue={setMiddle}
                        value={middle}
                        type="text"
                        placeholder="Middle Name"                      />
                    </Col>
                    <Col xs={12} lg={4}>
                      <InputGroup
                        label={"Last Name"}
                        setValue={setLastName}
                        value={lastName}
                        type="text"
                        placeholder="Last Name"
                        required={true}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Gender</Form.Label>
                        <Form.Select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Transgender">Transgender</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Time Format</Form.Label>
                        <Form.Select
                          disabled={ProfileDetails?.userType !== "Admin"}
                          value={format}
                          onChange={(e) => setFormat(e.target.value)}
                        >
                          <option value="">Select Format</option>
                          <option value="24">24 hrs</option>
                          <option value="12">12 hrs</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <InputGroup
                        label={"Mobile Number"}
                        setValue={setMobileNumber}
                        value={mobileNumber}
                        type="tel"
                        placeholder="Mobile Number"
                      // pattern={"[0-9]{10}"}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <InputGroup
                        label={"Email Address"}
                        setValue={setEmail}
                        value={email}
                        type="email"
                        placeholder="email@gmail.com"
                        required={ProfileDetails?.userType !== "Patient"}
                      />
                    </Col>
                    <Col xs={12} lg={12}>
                      <InputGroup
                        label={"Address"}
                        setValue={setAddress}
                        value={address}
                        as="textarea"
                      />
                    </Col>
                    <Col xs={12} lg={12}>
                      {ProfileDetails.userType === "Admin" && (
                        <InputGroup
                          label={"Company Name"}
                          setValue={setCompanyName}
                          value={companyName}
                          type="text"
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="card-footer-bottom footer-margin mt-3">
                    <Row className="text-center">
                      <Col xs={12} lg={12}>
                        <Button type="submit" className="theme-button mt-3">
                          {" "}
                          {loading ? <ClipLoader color="#fff" /> : "Update"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        {ProfileDetails.userType === "Admin" && <Card className="border-0 shadow-sm" body>
          <div className="header-card-title mb-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <MdCardMembership className="me-2 icon-color" />
                <h6 className="mb-0">Subscription</h6>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button
                  onClick={() => setShowCanclePlan(true)}
                  className="blue-theme-btn-outline"
                >
                  {planData?.data?.subscription?.cancel_at_period_end ? `Resume Plan` : `Cancel Plan`}
                </button>
                <button
                  onClick={() => setShowPlan(true)}
                  className="blue-theme-btn"
                  disabled={planData?.data?.subscription?.cancel_at_period_end}

                  style={{
                    opacity:
                      planData?.data?.subscription?.cancel_at_period_end ? "0.5" : "1"
                  }}
                >
                  Change Plan
                </button>
              </div>
            </div>
          </div>
          <Row className="mt-3">
            <Col xs={12} lg={6}>
              <Row>
                <Col xs={12} sm={12} lg={8}>
                  <div className="subcription-name">
                    <Form.Label className="fw-bold subcription-label">Subscription Name :</Form.Label>
                    <Form.Text className="mx-2 subcription-value">{planData?.data?.tier}</Form.Text>
                  </div>
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  <div className="subcription-status">
                    <Form.Label className="fw-bold subcription-status-label">Status :</Form.Label>
                    <Form.Text className="mx-2 subcription-status-value">{planData?.data?.subscription?.status || "Loading..."}</Form.Text>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <div className="subcription-desc">
                    <Form.Text className="w-100">
                      Designed for growing businesses, this plan offers enhanced features and increased capacity to scale your operations. With flexibility at its core, you can upgrade or downgrade at any time to match your evolving requirements.
                    </Form.Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6}>
              {/* <AmountCard data={planData?.data} /> */}
              <Row>
                <Col xs={12} sm={12} lg={12}>
                  <div className="subcription-date">
                    <Form.Label className="subcription-date-label">Start Date</Form.Label>
                    <Form.Text className="mx-2 subcription-date-value"> {planData?.data?.subscription?.current_period_start
                      ? formatDateToMMDDYYYY(new Date(planData?.data?.subscription?.current_period_start * 1000))
                      : "Loading..."}</Form.Text>
                  </div>
                  <div className="subcription-date">
                    <Form.Label className="subcription-date-label">{planData?.data?.subscription?.cancel_at_period_end ? `Plan Expire Date` : `Next Invoice Date`}   </Form.Label>
                    <Form.Text className="mx-2 subcription-date-value"> {planData?.data?.subscription?.next_invoice_date
                      ? formatDateToMMDDYYYY(new Date(planData?.data?.subscription?.next_invoice_date * 1000))
                      : "Loading..."}</Form.Text>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>}
      </Container>
    </>
  );
};

export default HOC({ Wcomponenet: Profile });
